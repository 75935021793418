import React, { useState, useEffect } from "react";
import trashIcon from "../../images/modal/trashicon.svg";
import RatingComponent from "../../utils/Rating";
import RatingModal from "../modal/RatingModal";
import PropTypes from 'prop-types';
import {Rate} from "antd";

function CategoriesNoAuth({ items, onDelete }) {
    const [ratings, setRatings] = useState({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);

    useEffect(() => {
        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};
        setRatings(storedRatings);
    }, [items]);

    const updateLocalStorage = (itemId, newRating) => {
        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};
        storedRatings[itemId] = newRating;
        localStorage.setItem('ratings', JSON.stringify(storedRatings));
        setRatings(storedRatings);  // Update the state with the new ratings
    };

    const handleDelete = (id, e) => {
        e.stopPropagation(); // Prevent triggering modal open click
        onDelete(id); // Call the passed onDelete function
        if (currentItem?.id === id) {
            setCurrentItem(null); // Deselect if the deleted item is the current item
        }
    };

    const openModal = (item) => {
        setCurrentItem(item);
        setShowUpdateModal(true);
    };

    const handleRatingChange = (newRating) => {
        if (currentItem) {
            updateLocalStorage(currentItem.id, newRating);
            closeModal();
        }
    };

    const closeModal = () => {
        setShowUpdateModal(false);
        setCurrentItem(null); // Optionally clear the current item if needed
    };

    return (
        <div className="px-3 pt-3 ">
            {/* Header Section */}
            <div className="flex justify-between mb-0">
                <div className="hidden md:flex items-center space-x-4">
                    <p className="text-[#141943] text-xl">Sélectionnés</p>
                    <p className="flex flex-row">
                    <span className=" text-2xl pb-1 ">{items.length} </span>
                    <span className=" text-1xl mt-2 ml-2"> /</span>
                        <span className="mt-2 text-sm pl-1"> 7</span>
                    </p>
                </div>
            </div>

            {/* Desktop View */}
            <div className="hidden md:block">
                {items.map((item) => (
                    <div
                        key={item.id}
                        className="flex items-center justify-between relative hover:bg-[#E9F2FB] rounded-md p-1"
                    >
                        <div
                            className="flex items-center cursor-pointer"
                            onMouseEnter={() => setHoveredItem(item.id)}
                            onMouseLeave={() => setHoveredItem(null)}
                            onClick={() => openModal(item)}
                        >
                            {hoveredItem === item.id && (
                                <img
                                    src={trashIcon}
                                    alt="Delete"
                                    className="object-cover ml-1 cursor-pointer mr-2"
                                    onClick={(e) => handleDelete(item.id, e)}
                                    aria-label="Delete item"
                                />
                            )}
                            <div
                                className="text-xs font-inter max-w-[10rem] md:max-w-[6rem] lg:max-w-[4rem] truncate text-ellipsis"
                                title={item.label} // Tooltip with full label
                            >
                                {item.label}
                            </div>
                        </div>

                        <Rate
                            count={3} // Set max rating
                            value={ratings[item.id] || 0}
                            onClick={() => openModal(item)}
                            className="text-yellow-400 text-xl"


                        />
                    </div>
                ))}
            </div>



            {/* Mobile View */}
            <div className="block lg:hidden cursor-pointer">
                {items.map((item) => (
                    <div
                        className="flex items-center justify-between mb-1.5 p-2 relative"

                    >
                        <div className="flex"
                             key={item.id}
                             onClick={() => openModal(item)}>
                            <div className="text-xs font-inter max-w-10rem">{item.label}</div>
                        </div>

                        {/*<RatingComponent*/}
                        {/*    ratingValue={ratings[item.id] || 0}*/}
                        {/*    maxRating={3}*/}
                        {/*    starColor="text-yellow-400"*/}
                        {/*    inactiveStarColor="text-gray-300"*/}
                        {/*    size="w-8 h-8"*/}
                        {/*/>*/}
                        <Rate
                            count={3} // Set max rating
                            value={ratings[item.id] || 0}
                            onClick={() => openModal(item)}
                            className="text-yellow-400 text-xl"

                        />
                    </div>
                ))}
            </div>

            {showUpdateModal && currentItem && (
                <RatingModal
                    visible={showUpdateModal}
                    item={currentItem}
                    rating={ratings[currentItem.id] || 0}
                    onClose={closeModal}
                    onRatingChange={handleRatingChange}
                    onDelete={(e) => handleDelete(currentItem.id, e)}

                />
            )}
        </div>
    );

}

CategoriesNoAuth.propTypes = {
    items: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CategoriesNoAuth;