import { Link } from "react-router-dom";
import React from "react";

export default function FormationSearchFooter() {
    return (
        <div className="text-black font-poppins bg-transparent     p-3 my-2">
             <div className="text-sm flex justify-center item-center gap-3 text-gray-500 ">
                    <Link to="/mentions_legales" className=" underline text-gray-600">
                        Mentions légales
                    </Link>
                    <Link to="/conditions_generales" className=" underline text-gray-600">
                        Conditions générales
                    </Link>
                </div>
        </div>
    );
}
