import React from 'react';
import { createContext, useContext, useState } from 'react';
import { useAxios } from './caller.service';
import { useNavigate } from 'react-router-dom';
import { CryptoService } from './crypte.service';
import { LocalStorageService } from './localStorage.service';

const AuthContext = createContext();


export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));
    const navigate = useNavigate();
    const AxiosInstance = useAxios();

    // const login = async (email, password) => {
    //     const data = {
    //         user_email: email,
    //         user_password: password,
    //     };
    //
    //     try {
    //         const response = await AxiosInstance.post('/user/login', data);
    //
    //         if (response.data.status === "success") {
    //             const { user_id, user_name, user_surname, user_email, access_token } = response.data.data;
    //             // console.log(user_id)
    //             localStorage.setItem('user_id', CryptoService.encryptData(user_id.toString()));
    //             localStorage.setItem('user_name', CryptoService.encryptData(user_name.toString()));
    //             localStorage.setItem('user_surname', CryptoService.encryptData(user_surname.toString()));
    //             localStorage.setItem('user_email', CryptoService.encryptData(user_email.toString()));
    //             localStorage.setItem('access_token', CryptoService.encryptData(access_token.toString()));
    //
    //             setIsAuthenticated(true);
    //             return response.data;
    //         } else {
    //             setIsAuthenticated(false);
    //             console.error("Unexpected API response structure", response);
    //         }
    //         return response.data;
    //     } catch (error) {
    //         setIsAuthenticated(false);
    //         console.error('Login failed:', error);
    //         throw error;
    //     }
    // };


    const login = async (email, password) => {
        const data = {
            user_email: email,
            user_password: password,
        };

        try {
            const response = await AxiosInstance.post('/user/login', data);

            if (response.data.status === "success") {
                const { user_id, user_name, user_surname, user_email, access_token } = response.data.data;
                // console.log(user_id)
                localStorage.setItem('user_id', CryptoService.encryptData(user_id.toString()));
                localStorage.setItem('user_name', CryptoService.encryptData(user_name.toString()));
                localStorage.setItem('user_surname', CryptoService.encryptData(user_surname.toString()));
                localStorage.setItem('user_email', CryptoService.encryptData(user_email.toString()));
                localStorage.setItem('access_token', CryptoService.encryptData(access_token.toString()));

                /** customized search **/
                const filteredData = response.data.data?.filtered_data;
                const formation = filteredData?.formation || '';
                const formationParts = formation.split('|');
                const selectedStudLevel = formationParts[0] ? formationParts[0].split(';').map(value => parseInt(value)) : [];
                // const selectedArea = formationParts[2] ? formationParts[2].split(';').map(value => parseInt(value)) : [];
                let selectedArea = [];
                if (formationParts[1] && formationParts[1].trim() === "[null]") {
                    // Handle the case where `[null]` is explicitly present
                    selectedArea = formationParts[2]
                        ? formationParts[2].split(';').map(value => parseInt(value))
                        : [];
                } else {
                    // Handle the normal case
                    selectedArea = formationParts[1]
                        ? formationParts[1].split(';').map(value => parseInt(value))
                        : [];
                }


                const formSearchData = JSON.parse(localStorage.getItem("formSearchData")) || {
                    selectedStudLevel: [],
                    selectedArea: [],
                };
                formSearchData.selectedStudLevel = selectedStudLevel;
                formSearchData.selectedArea = selectedArea;
                localStorage.setItem("formSearchData", JSON.stringify(formSearchData));

                const metier = filteredData?.metiere || '';
                const metiersParts = metier.split('|');
                const selectedStudLevelm = metiersParts[0] ? metiersParts[0].split(';').map(value => parseInt(value)) : [];
                // const selectedbusinessSector = metiersParts[2] ? metiersParts[2].split(';').map(value => parseInt(value)) : [];

                let selectedbusinessSector = [];
                if (metiersParts[1] && metiersParts[1].trim() === "[null]") {
                    // Handle the case where `[null]` is explicitly present
                    selectedbusinessSector = metiersParts[2]
                        ? metiersParts[2].split(';').map(value => parseInt(value))
                        : [];
                } else {
                    // Handle the normal case
                    selectedbusinessSector = metiersParts[1]
                        ? metiersParts[1].split(';').map(value => parseInt(value))
                        : [];
                }


                const formMetiersSearchData = JSON.parse(localStorage.getItem("formMetiersSearchData")) || {
                    selectedStudyLevel: [],
                    selectedBusinessSector: [],
                };
                formMetiersSearchData.selectedStudyLevel = selectedStudLevelm;
                formMetiersSearchData.selectedBusinessSector = selectedbusinessSector;
                localStorage.setItem("formMetiersSearchData", JSON.stringify(formMetiersSearchData));
                /** end of customized search **/

                setIsAuthenticated(true);
                return response.data;
            } else {
                setIsAuthenticated(false);
                console.error("Unexpected API response structure", response);
            }
            return response.data;
        } catch (error) {
            setIsAuthenticated(false);
            console.error('Login failed:', error);
            throw error;
        }
    };


        let signUp = async (email, password, sub, selectedUserSurname, phoneNumber,googleusername,googlelastname,selectedUserStatus,selectedStudyLevel,selectedBacType,selectedSpecializations,selectedAreaStudies) => {
            let response;

            const bac_spe_techno = selectedBacType === 2 ? selectedSpecializations : null;
            const bac_spe_pro = selectedBacType === 3 ? selectedSpecializations : null;

            const data = {
                user_name: selectedUserSurname || null,
                user_surname: selectedUserSurname || null,
                user_year_of_birth: '0',
                user_email: email,
                user_password: password,
                id_provider_auth: sub,
                phone_number: phoneNumber || '0',
                google_username: googleusername || "unknown",
                google_lastname: googlelastname || "unknown",
                code_postal: '0',
                country: '0',
                bac_type: selectedBacType || 0,
                studlevel_id: selectedStudyLevel || null,
                status_id: selectedUserStatus || null,
                area_stud_id: selectedAreaStudies || null,
                bac_spe_techno: bac_spe_techno,
                bac_spe_pro: bac_spe_pro,
            };

            if (selectedSpecializations && selectedSpecializations.length) {
                selectedSpecializations.forEach((value, index) => {
                    data[`bac_spe_general${index + 1}`] = value || null;
                });
            }

        try {
            response = await AxiosInstance.post('/user/signup', data);

            if (response.data?.status === 'success') {
                const { user_id, user_name, user_surname, user_email, access_token } = response.data.data;
                localStorage.setItem('user_id', CryptoService.encryptData(user_id.toString()));
                localStorage.setItem('user_name', CryptoService.encryptData(user_name.toString()));
                localStorage.setItem('user_surname', CryptoService.encryptData(user_surname.toString()));
                localStorage.setItem('user_email', CryptoService.encryptData(user_email.toString()));
                localStorage.setItem('access_token', CryptoService.encryptData(access_token.toString()));

                /** customized search **/
                const filteredData = response.data.data?.filtered_data;
                const formation = filteredData?.formation || '';
                const formationParts = formation.split('|');
                const selectedStudLevel = formationParts[0] ? formationParts[0].split(';').map(value => parseInt(value)) : [];
                // const selectedArea = formationParts[2] ? formationParts[2].split(';').map(value => parseInt(value)) : [];
                let selectedArea = [];
                if (formationParts[1] && formationParts[1].trim() === "[null]") {
                    // Handle the case where `[null]` is explicitly present
                    selectedArea = formationParts[2]
                        ? formationParts[2].split(';').map(value => parseInt(value))
                        : [];
                } else {
                    // Handle the normal case
                    selectedArea = formationParts[1]
                        ? formationParts[1].split(';').map(value => parseInt(value))
                        : [];
                }


                const formSearchData = JSON.parse(localStorage.getItem("formSearchData")) || {
                    selectedStudLevel: [],
                    selectedArea: [],
                };
                formSearchData.selectedStudLevel = selectedStudLevel;
                formSearchData.selectedArea = selectedArea;
                localStorage.setItem("formSearchData", JSON.stringify(formSearchData));

                const metier = filteredData?.metiere || '';
                const metiersParts = metier.split('|');
                const selectedStudLevelm = metiersParts[0] ? metiersParts[0].split(';').map(value => parseInt(value)) : [];
                // const selectedbusinessSector = metiersParts[2] ? metiersParts[2].split(';').map(value => parseInt(value)) : [];

                let selectedbusinessSector = [];
                if (metiersParts[1] && metiersParts[1].trim() === "[null]") {
                    // Handle the case where `[null]` is explicitly present
                    selectedbusinessSector = metiersParts[2]
                        ? metiersParts[2].split(';').map(value => parseInt(value))
                        : [];
                } else {
                    // Handle the normal case
                    selectedbusinessSector = metiersParts[1]
                        ? metiersParts[1].split(';').map(value => parseInt(value))
                        : [];
                }


                const formMetiersSearchData = JSON.parse(localStorage.getItem("formMetiersSearchData")) || {
                    selectedStudyLevel: [],
                    selectedBusinessSector: [],
                };
                formMetiersSearchData.selectedStudyLevel = selectedStudLevelm;
                formMetiersSearchData.selectedBusinessSector = selectedbusinessSector;
                localStorage.setItem("formMetiersSearchData", JSON.stringify(formMetiersSearchData));
                /** end of customized search **/


                setIsAuthenticated(true);
                return response;
            } else {
                console.error("Unexpected API response structure", response);
            }
        } catch (error) {
            console.error('Signup failed:', error);
            return response;
        }
    };


    const confirmEmail = async (otp, email) => {
        try {
            let response = await AxiosInstance.post('/user/confirm-email', {}, {
                params: {
                    token: otp,
                    email: email
                }
            });

            if (response.data.status === 'success') {
                setIsAuthenticated(true);
            }
            return response;
        } catch (error) {
            // Handle confirm email error
            console.error('Confirm email failed:', error);
            throw error;
        }
    };


    let checkEmailGoogle = async (email, providerAuth) => {
        try {
            let response = await AxiosInstance.post('/user/check-email-google', {}, {
                params: {
                    new_email: email,
                    id_provider_auth: providerAuth
                }
            });
            if (response.data.status === 'success') {

                const filteredData = response.data.data?.filtered_data;
                const formation = filteredData?.formation || '';
                const formationParts = formation.split('|');
                const selectedStudLevel = formationParts[0] ? formationParts[0].split(';').map(value => parseInt(value)) : [];
                // const selectedArea = formationParts[2] ? formationParts[2].split(';').map(value => parseInt(value)) : [];
                let selectedArea = [];
                if (formationParts[1] && formationParts[1].trim() === "[null]") {
                    // Handle the case where `[null]` is explicitly present
                    selectedArea = formationParts[2]
                        ? formationParts[2].split(';').map(value => parseInt(value))
                        : [];
                } else {
                    // Handle the normal case
                    selectedArea = formationParts[1]
                        ? formationParts[1].split(';').map(value => parseInt(value))
                        : [];
                }


                const formSearchData = JSON.parse(localStorage.getItem("formSearchData")) || {
                    selectedStudLevel: [],
                    selectedArea: [],
                };
                formSearchData.selectedStudLevel = selectedStudLevel;
                formSearchData.selectedArea = selectedArea;
                localStorage.setItem("formSearchData", JSON.stringify(formSearchData));

                const metier = filteredData?.metiere || '';
                const metiersParts = metier.split('|');
                const selectedStudLevelm = metiersParts[0] ? metiersParts[0].split(';').map(value => parseInt(value)) : [];
                // const selectedbusinessSector = metiersParts[2] ? metiersParts[2].split(';').map(value => parseInt(value)) : [];

                let selectedbusinessSector = [];
                if (metiersParts[1] && metiersParts[1].trim() === "[null]") {
                    // Handle the case where `[null]` is explicitly present
                    selectedbusinessSector = metiersParts[2]
                        ? metiersParts[2].split(';').map(value => parseInt(value))
                        : [];
                } else {
                    // Handle the normal case
                    selectedbusinessSector = metiersParts[1]
                        ? metiersParts[1].split(';').map(value => parseInt(value))
                        : [];
                }


                const formMetiersSearchData = JSON.parse(localStorage.getItem("formMetiersSearchData")) || {
                    selectedStudyLevel: [],
                    selectedBusinessSector: [],
                };
                formMetiersSearchData.selectedStudyLevel = selectedStudLevelm;
                formMetiersSearchData.selectedBusinessSector = selectedbusinessSector;
                localStorage.setItem("formMetiersSearchData", JSON.stringify(formMetiersSearchData));
                /** end of customized search **/


                setIsAuthenticated(true);
            }
            return response;
        } catch (error) {
            console.error('checkEmailGoogle failed:', error);
            throw error;
        }
    }
    const logout = () => {
        LocalStorageService.logout();
        setIsAuthenticated(false);
        navigate('/');
        // window.location.reload();

    };

    let googleLogin = async (token) => {
        try {
        } catch (error) {
            console.error('Google login failed:', error);
            throw error;
        }
    };

    let googleSignUp = async (token) => {
        try {

        } catch (error) {
            console.error('Google signUp failed:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, signUp, googleSignUp, googleLogin, confirmEmail, checkEmailGoogle, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

// Use Auth Context
export function useAuth() {
    const context = useContext(AuthContext);
    return context;
}

