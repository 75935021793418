import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import TinderCard from "react-tinder-card";
import { useNavigate } from "react-router-dom";
import { useQuestionsService } from "../services/questions.service";
import { useProgress } from "../context/ProgressContext";
import LayoutNo from "../utils/LayoutNo";
import { HeartFilled, CloseOutlined,StarFilled, StepBackwardFilled , QuestionOutlined } from '@ant-design/icons';
import {Button, Modal} from 'antd';
import Goback from "../images/goback.svg"
import {Helmet} from "react-helmet";

const swipeScores = { up: 5, down: 3, left: 1, right: 4 };
const pageProgress = {
    "Environnement de travail": { start: 5, end: 45 },
    "Personnalité": { start: 45, end: 95 }
};

export default function Testing() {
    const navigate = useNavigate();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [responses, setResponses] = useState({});
    const childRefs = useRef([]);
    const { setCurrentPage, setFinalSectionProgress, currentPageProgress } = useProgress();
    const [showTutorial, setShowTutorial] = useState(true);
    const [swipeHistory, setSwipeHistory] = useState([]);

    const { getQuestions } = useQuestionsService();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await getQuestions();
            if (response.data.status === "success") {
                setQuestions(response.data.data);
            }
        } catch (err) {
            console.error("Error fetching questions:", err);
        } finally {
            setLoading(false);
        }
    };

    const updateResponses = (direction) => {
        const updatedResponses = { ...responses };
        const questionId = questions[currentQuestionIndex]?.question_id;

        if (questionId) {
            updatedResponses[questionId] = swipeScores[direction];
        }

        setResponses(updatedResponses);
        localStorage.setItem("questionsScores", JSON.stringify(updatedResponses));
        return updatedResponses;
    };

    const calculateSectionProgress = useCallback((questionPage, updatedResponses) => {
        const { start, end } = pageProgress[questionPage] || { start: 0, end: 100 };
        const sectionQuestions = questions.filter(q => q.question_page === questionPage);

        const totalQuestionsInSection = sectionQuestions.length;
        if (totalQuestionsInSection === 0) return start;

        const answeredQuestions = sectionQuestions.filter(q => updatedResponses[q.question_id] !== undefined).length;

        return start + (answeredQuestions / totalQuestionsInSection) * (end - start);
    }, [questions]);


    const swiped = (direction) => {
        if (!swipeScores[direction]) return;

        const updatedResponses = updateResponses(direction);
        const currentPage = questions[currentQuestionIndex]?.question_page;
        setSwipeHistory((prev) => [...prev, currentQuestionIndex]);

        setCurrentPage(currentPage);
        setFinalSectionProgress(calculateSectionProgress(currentPage, updatedResponses));

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(prev => prev + 1);
        } else {
            navigate("/public/test-orientation/sujets");
        }
    };

    // const manualSwipe = (direction) => {
    //     const topCardIndex = questions.length - 1 - currentQuestionIndex;
    //     if (childRefs.current[topCardIndex]) {
    //         childRefs.current[topCardIndex].swipe(direction);
    //     }
    // };
    const manualSwipe = (direction) => {
        const topCardIndex = questions.length - 1 - currentQuestionIndex;
        if (childRefs.current[topCardIndex]) {
            childRefs.current[topCardIndex].swipe(direction);
        }
    };


    useEffect(() => {
        if (currentPageProgress === null || currentPageProgress === undefined) {
            setCurrentPage("Environnement de travail");
            setFinalSectionProgress(5);
        }
    }, [currentPageProgress, setFinalSectionProgress, setCurrentPage]);



    const goBack = () => {
        if (swipeHistory.length === 0) return;

        setSwipeHistory((prev) => {
            const history = [...prev];
            const lastSwipedIndex = history.pop();
            setCurrentQuestionIndex(lastSwipedIndex);

            setTimeout(() => {
                childRefs.current[questions.length - 1 - lastSwipedIndex]?.restoreCard();
            }, 0);
            const updatedResponses = { ...responses };
            const lastQuestion = questions[lastSwipedIndex];
            if (updatedResponses[lastQuestion?.question_id]) {
                delete updatedResponses[lastQuestion.question_id];
                setResponses(updatedResponses);
                localStorage.setItem("questionsScores", JSON.stringify(updatedResponses));
            }
            setCurrentPage(lastQuestion?.question_page);
            setFinalSectionProgress(calculateSectionProgress(lastQuestion?.question_page, updatedResponses));

            return history;
        });
    };


    return (
        <>
            <Helmet>
                <title>Test d'Orientation - Trouvez Votre Voie</title>
                <meta name="description" content="Passez notre test d'orientation et découvrez la carrière faite pour vous." />
                <link rel="canonical" href="https://linkyways.com/public/test-orientation" />
            </Helmet>

        <LayoutNo>
            <div className="bg-white overflow-hidden h-screen">

                <div className="flex justify-start gap-2  p-1  bg-white  md:mt-7 mt-28">
                        <Button  type="primary"
                                 shape="circle"
                                 icon={<StepBackwardFilled style={{ fontSize: "28px" }} className="text-gray-900" />}
                                 onClick={goBack}
                                 // disabled={swipeHistory.length === 0}
                                 className={`bg-white shadow-lg ${swipeHistory.length === 0 ? "invisible" : ""}`}
                        />
                </div>

                <div className="flex justify-center p-1  md:-mt-5 -mt-28 items-center bg-white overflow-hidden">

                    {loading ? (
                        <div className="w-[80vw] max-w-[260px] h-[300px] rounded-2xl bg-gray-100 animate-pulse shadow-sm relative flex items-center md:mt-16 mt-32  md:mb-8 mb-16 justify-center">
                            <div className="h-4 w-10 bg-gray-200 rounded-md mb-5">
                                <p className="h-2 w-full bg-gray-200 rounded-md mt-4"></p>
                                <p className="h-2 w-full bg-gray-200 rounded-md -mt-2"></p>
                            </div>
                        </div>
                    ) : (
                        <div className="w-[90vw] max-w-[260px] h-[300px] md:mt-16 mt-32 relative md:mb-10 mb-16  ">
                            {questions.slice().reverse().map((question, index) => (
                                <TinderCard
                                    // ref={(el) => (childRefs.current[questions.length - 1 - index] = el)}
                                    ref={(el) => (childRefs.current[index] = el)}
                                    className="absolute "
                                    key={question.question_id}
                                    onSwipe={(dir) => swiped(dir)}
                                >
                                    <div className={`relative w-[80vw] max-w-[260px] h-[300px] rounded-2xl bg-cover bg-center p-2 flex items-center justify-center shadow-sm cursor-pointer  bg-yellow-100`}>
                                        <p className="text-xl font-semibold text-center">{question.question_text}</p>
                                        <svg className="absolute bottom-0 left-1/2 transform -translate-x-1/2" width="100" height="50" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                                            <ellipse cx="50" cy="50" rx="50" ry="25" fill="white" fillOpacity="0.8"/>
                                        </svg>
                                    </div>
                                </TinderCard>
                            ))}

                        </div>
                    )}
                </div>
                <div className="flex justify-center gap-2    items-center bg-white">

                    <Button
                        type="primary"
                        shape="circle"
                        className="shadow-lg flex items-center p-4 border-1 border-red-500 justify-center  bg-white"
                        icon={<CloseOutlined style={{ fontSize: "28px" }} className="text-red-500" />}
                        onClick={() => manualSwipe("left")}
                    />
                    <Button
                        type="primary"
                        shape="circle"
                        className="shadow-lg flex items-center p-4 border-1 border-yellow-500 justify-center  bg-white"
                        icon={< QuestionOutlined style={{ fontSize: "28px" }} className="text-yellow-500" />}
                        onClick={() => manualSwipe("down")}
                    />


                    <Button
                        type="primary"
                        shape="circle"
                        className="shadow-lg flex items-center p-4 border-1 border-green-500 justify-center  bg-white"
                        icon={<HeartFilled style={{ fontSize: "28px" }} className="text-green-500" />}
                        onClick={() => manualSwipe("right")}
                    />
                    <Button
                        type="primary"
                        shape="circle"
                        className="shadow-lg flex items-center p-4 border-1 border-blue-500 justify-center  bg-white"
                        icon={<StarFilled  style={{ fontSize: "28px" }} className="text-blue-500" />}
                        onClick={() => manualSwipe("up")}
                    />

                </div>
            </div>
            <Modal title={<p className="flex justify-center font-poppins">Comment ça marche ?</p>} visible={showTutorial} centered={true} closeIcon={false} onCancel={() => setShowTutorial(false)} footer={null} width={600}>
                <p>Swipe ou utilise les boutons pour répondre :</p>
                <ul className="text-md mt-3 text-left space-y-3 font-poppins ">
                    <li><StarFilled className="text-blue-500 mb-2" /> <b>Swipe vers le haut :</b> Complètement d'accord</li>
                    <li><HeartFilled className="text-green-500 mb-2" /> <b>Swipe à droite :</b> Oui !</li>
                    <li><QuestionOutlined className="text-yellow-500 mb-2" /> <b>Swipe vers le bas :</b> Pas sûr...</li>
                    <li><CloseOutlined className="text-red-500 " /> <b>Swipe à gauche :</b> Non </li>
                </ul>
                <div className="flex justify-center">
                    <Button type="primary" onClick={() => setShowTutorial(false)} className="mt-4">J'ai compris !</Button>
                </div>
            </Modal>
        </LayoutNo>
        </>
    );
}


