import React, {useState, useEffect} from "react";
import LayoutNo from "../../utils/LayoutNo";
import {Tag, Alert, notification} from "antd";
import Logo from "../../images/Linkywayslogo.svg";
import {Avatar} from "antd";
import {useRef} from "react";
import {UserOutlined, SendOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {useFormationService} from "../../services/formation.service";
import {Link} from "react-router-dom"
import {Button, Select} from "antd";
import {useAuth} from "../../services/auth.provider";
import LoginModal from "../../components/modal/auth/LoginModal";
import {Spin} from 'antd';
import {CryptoService} from "../../services/crypte.service";
import ReactMarkdownComponent from "../MessageFormat";
import {useGuidancesService} from "../../services/guidance.service";
import {useChatService} from "../../services/chatQuestion.service";
import { Carousel } from 'antd';

export default function NonAuthenticatedUserChatbot({ renderContent }) {
    const [messages, setMessages] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const messagesEndRef = useRef(null);
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {getChatFaqs} = useFormationService();
    const {isAuthenticated} = useAuth();
    const [input, setInput] = useState("");
    const [userId, setUserId] = useState(0);
    const { getChatbotResponse } = useGuidancesService();
    const { getChatQuestions,saveComment ,saveNonAuthMessage} = useChatService();
    const [isCommentMode, setIsCommentMode] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
    const [questionIndex, setQuestionIndex] = useState(1);

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);



    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await getChatFaqs();
                let allFaqs = response.data.data;
                setFaqs(allFaqs);
            } catch (error) {
                setError("Error fetching FAQs");
            } finally {
                setLoading(false);
            }
        };
        fetchFaqs();
    }, []);




    const handleQuestionClick = (selectedFaq) => {
        if (!selectedFaq) return;

        const userMessage = { role: "user", content: selectedFaq.question };
        setMessages((prev) => [...prev, userMessage]);

        const sectionFaqs = faqs.filter((faq) => faq.section_id === selectedFaq.section_id);
        let responses = [];
        let types = [];
        let cta = null;
        let buttonText = null;

        sectionFaqs.forEach((faq) => {
            for (let i = 1; i <= 23; i++) {
                const response = faq[`response_${i}`];
                const type = faq[`type_${i}`];

                if (response && type) {
                    responses.push(response);
                    types.push(type);
                }
            }

            if (faq.cta) {
                cta = faq.cta;
                buttonText = faq.button;
            }
        });

        setTimeout(() => {
            const botMessage = {
                role: "assistant",
                content: renderContent(responses, types, cta, buttonText),
            };

            setMessages((prev) => [...prev, botMessage]);
            setAnsweredQuestions((prev) => [...prev, selectedFaq.question]);
            setFaqs((prev) => prev.filter((q) => q.question !== selectedFaq.question));
        }, );
    };


    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [messages]);


    useEffect(() => {
        if (isAuthenticated) {
            const user = localStorage.getItem('user_id');
            if (user) {
                const decryptedId = CryptoService.decryptData(user);
                setUserId(decryptedId);
            }
        }
    }, [isAuthenticated]);

    const getAttemptsData = () => {
        const storedData = localStorage.getItem("chatbot_attempts");
        return storedData ? JSON.parse(storedData) : { count: 0, timestamp: null };
    };

    const resetAttemptsIfNeeded = () => {
        const data = getAttemptsData();
        const now = Date.now();

        if (data.timestamp && now - data.timestamp > 86400000) {
            localStorage.setItem("chatbot_attempts", JSON.stringify({ count: 0, timestamp: now }));
        }
    };

    const incrementAttempts = () => {
        let data = getAttemptsData();
        data.count += 1;
        data.timestamp = data.timestamp || Date.now();
        localStorage.setItem("chatbot_attempts", JSON.stringify(data));
    };


    const sendMessage = async () => {
        resetAttemptsIfNeeded();
        let attemptsData = getAttemptsData();
        // let questionIndex = parseInt(localStorage.getItem("questionIndex")) || 1;
        // let questionId = ((questionIndex - 1) % 15) + 1;
        let questionId = ((questionIndex - 1) % 15) + 1;

        if (!input.trim()) return;
        const userMessage = { role: "user", content: input };
        setMessages([...messages, userMessage]);
        setInput("");
        incrementAttempts();

        if (!isAuthenticated) {
            await saveNonAuthMessage(input);
        }



        if (attemptsData.count >= 10 || !isAuthenticated) {
            // Call different chat questions based on authentication
            const response = await getChatQuestions(isAuthenticated ? 1 : 0,questionId);
            const answer = response?.data?.answers?.[0];
            console.log("answer", answer)

            setTimeout(() => {
                console.log("Inside setTimeout block");
                // localStorage.setItem("questionIndex", questionIndex + 1);
                setQuestionIndex((prevIndex) => (prevIndex % 15) + 1);

                setMessages((prev) => [...prev, { role: "assistant",
                    content: (
                        <div>
                            <div style={{ display: 'flex', gap: '15px' }}>
                                {answer.buttons.filter(button => button.url) .map((button, index) => (
                                    button.url  && (
                                        <div key={index}>
                                            {button.url === "/login" ? (
                                                <button onClick={showModal} className="text-blue-500 font-semibold underline">
                                                    Se connecter
                                                </button>
                                            ) : (
                                                <Link to={button.url} className="text-blue-500 font-semibold underline">
                                                    {button.text}
                                                </Link>
                                            )}
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    ),typing: true
                }]);
                typeWriterEffect(answer.answer, answer.buttons);

            }, );
            return;
        }

        setMessages([...messages, userMessage, { role: "assistant", content: "loading" }]);


        try {
            const botMessage = await getChatbotResponse(userId, input);
            if (botMessage) {
                setMessages((prev) => [...prev.slice(0, -1), { role: "assistant", content: "", fullContent: botMessage.answer,typing: false }]);
                typeWriterEffect(botMessage.answer);

            }
        } catch (error) {
            console.error("Error fetching response:", error);
            setMessages((prev) => [...prev.slice(0, -1)]);
        } finally {
        }
    };


    const typeWriterEffect = (text, buttons) => {
        let i = 0;
        const interval = setInterval(() => {
            setMessages((prevMessages) => {
                let lastMessage = prevMessages[prevMessages.length - 1];

                if (i < text.length) {
                    lastMessage = {
                        ...lastMessage,
                        content: (
                            <div>
                                <p>{text.substring(0, i + 1)}</p>
                                <div style={{ display: 'flex', gap: '15px' }}>
                                    {buttons?.filter(button => button.url)?.map((button, index) => (
                                        <div key={index}>
                                            {button.url === "/login" ? (
                                                <button onClick={showModal} className="text-blue-500 font-semibold underline">
                                                    Se connecter
                                                </button>
                                            ) : (
                                                <Link to={button.url} className="text-blue-500 font-semibold underline">
                                                    {button.text}
                                                </Link>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ),
                        typing: true
                    };
                    i++;
                } else {
                    lastMessage = { ...lastMessage, typing: false };
                    clearInterval(interval);
                }

                return [...prevMessages.slice(0, -1), lastMessage];
            });
        }, 0.1); // Adjust typing speed here
    };


    const handleSendComment = async () => {
        if (!input.trim()) return;

        try {
            await saveComment(input,userId);
            notification.success({
                message: 'Bien reçu',
                placement: 'topRight',
            });
        } catch (error) {
            console.error("Error posting comment:", error);
        }
        setInput("");
        setIsCommentMode(false);
    };


    return (
        <LayoutNo>
            <div className="flex h-screen">
                <div className="flex flex-col flex-auto h-full p-2">
                    <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl h-full p-2">
                        <div className="flex flex-col h-full overflow-x-auto mb-4">
                            <div className="flex-1 md:p-4 p-2 space-y-4 ">
                                <div
                                    className={`flex justify-center text-center items-center ${messages.length === 0 ? 'h-full' : ''}`}>
                                    <div className="space-y-2 md:mt-0 mt-5 ">
                                        { answeredQuestions.length === 0 && !messages.some(msg => msg.role === "assistant") &&(
                                            <>
                                                <p className="text-4xl md:-mb-1 -mb-4 font-semibold text-gray-900 md:mt-1 mt-5">
                                                    🎯
                                                </p>
                                                <p className="text-lg font-semibold text-gray-900 md:mt-1 mt-5">
                                                    Pose-moi une question sur ton orientation
                                                </p>
                                            </>
                                        )}
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                            {loading ? (
                                                <div
                                                    className="flex justify-center items-center md:-mr-48"
                                                >
                                                    <Spin tip="Loading" size="large"/>
                                                </div>

                                            ) : answeredQuestions.length === 0 && !messages.some(msg => msg.role === "assistant") ?(
                                                faqs
                                                    .filter((faq) => !answeredQuestions.includes(faq.question)) // Exclude answered ones
                                                    .slice(0, 4) // Ensure only 4 FAQs are shown
                                                    .map((faq, index) => (
                                                            <div
                                                                key={faq.question}
                                                                onClick={() => handleQuestionClick(faq)}
                                                                className="px-2 py-2 cursor-pointer bg-white text-xs leading-snug rounded-full shadow-md text-black whitespace-nowrap overflow-hidden text-ellipsis"
                                                            >
                                                                {faq.question}
                                                            </div>
                                                        )
                                                    )
                                            ):(null)}
                                        </div>

                                    </div>
                                </div>

                                {messages.length > 0 && messages.map((msg, index) => (
                                    <div
                                        key={index}
                                        className={`flex ${msg.role === "assistant" ? "justify-start" : "justify-end"} gap-2.5`}>
                                        <div className="grid  break-words">
                                            {/*<div ref={messagesEndRef}/>*/}

                                            {/*{msg.role === "assistant" && (*/}
                                            {/*    <div className="flex justify-start">*/}
                                            {/*        <Avatar src={Logo} className="p-1 border-1 border-blue-500 mb-1"  size="small"/>*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                            {/*{msg.role === "user" && (*/}
                                            {/*    <div className="flex justify-end">*/}
                                            {/*        /!*<h5 className="text-sm px-2 pt-1 font-semibold text-gray-900">me</h5>*!/*/}
                                            {/*        <Avatar size="small" className="my-1" icon={<UserOutlined />} />*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                            <div
                                                className={`px-2 pt-1 rounded-3xl ${
                                                    msg.role === "user"
                                                        ? "bg-blue-500 text-white rounded-tr-none max-w-xs md:max-w-md"
                                                        : " text-gray-900 rounded-tl-none "
                                                }`}
                                            >
                                                {msg.content === "loading" ? (
                                                    <div className="flex space-x-1 p-2 min-w-[40px] justify-center">
                                                        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></span>
                                                        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0.2s" }}></span>
                                                        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0.4s" }}></span>
                                                    </div>

                                                ) : (
                                                    <>
                                                        <div ref={messagesEndRef}/>
                                                        <h5 className="text-sm font-normal pt-1 leading-snug break-words">
                                                            {/*<ReactMarkdownComponent content={msg.content}/>*/}
                                                            {msg.content}
                                                            {/*{msg.fullContent ? <ReactMarkdownComponent content={msg.fullContent} /> : msg.content}*/}

                                                        </h5>
                                                    </>


                                                )}
                                            </div>

                                            {/*<div ref={messagesEndRef} />*/}

                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col items-center  ">
                            {/*<div className="grid grid-cols-1 gap-2 mb-2  ">*/}
                                <div className="grid grid-cols-1 gap-2 mb-2 lg:px-8 xl:px-10 2xl:px-16">

                                {  (answeredQuestions.length !== 0 || messages.length > 0) ? (
                                    <Carousel
                                        dots={false}
                                        swipeToSlide={true}
                                        draggable
                                        responsive={[
                                            {
                                                breakpoint: 768,
                                                settings: {
                                                    slidesToShow: 2,
                                                },
                                            },
                                            {
                                                breakpoint: 1024,
                                                settings: {
                                                    slidesToShow: 3,
                                                },
                                            },
                                            {
                                                breakpoint: 9999,
                                                settings: {
                                                    slidesToShow: 3,
                                                },
                                            },
                                        ]}
                                    >
                                        {faqs
                                            .filter((faq) => !answeredQuestions.includes(faq.question))
                                            .slice(0, 5)
                                            .map((faq) => (
                                                <div key={faq.question} className="px-2">
                                                    <div
                                                        onClick={() => {
                                                            if (!lastMessage || !lastMessage.typing) {
                                                                handleQuestionClick(faq);
                                                            }
                                                        }}
                                                        className={`p-2 mb-2 text-center cursor-pointer bg-white text-xs leading-snug rounded-full shadow-md text-black ${
                                                            lastMessage && lastMessage.typing ? 'opacity-50 cursor-not-allowed' : ''
                                                        }`}
                                                    >
                                                    <span className="truncate overflow-hidden whitespace-nowrap block max-w-full">
                                                        {faq.question}
                                                    </span>
                                                    </div>
                                                </div>
                                            ))}
                                    </Carousel>
                                ):(null)}
                            </div>

                        </div>
                        <div className="flex flex-col items-center w-full">
                            <div className="flex flex-row items-center py-2 rounded-xl bg-white w-full md:px-4 px-2 mb-2 shadow-md">
                                <textarea
                                    style={{ fontSize: "16px" }}
                                    disabled={lastMessage && (lastMessage.content === "loading"  || lastMessage.typing)}
                                    className={`flex-1 p-2 border-none rounded-md  focus:outline-none w-full resize-none overflow-hidden  placeholder:italic ${isCommentMode ? "italic" : ""}`}
                                    placeholder={isCommentMode ? "Commentaire..." : "Qu'est-ce qu'un BTS NDRC ?"}
                                    value={input}
                                    rows={1}
                                    onChange={(e) => {
                                        setInput(e.target.value);
                                        e.target.style.height = "auto";
                                        e.target.style.height = e.target.scrollHeight + "px";
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            isCommentMode ? handleSendComment(input) : sendMessage();

                                            setTimeout(() => {
                                                e.target.style.height = "auto";
                                            }, 0);
                                        }
                                    }}
                                />

                                <Button
                                    disabled={lastMessage && (lastMessage.content === "loading"  || lastMessage.typing) || !input}
                                    onClick={(e) => {
                                        isCommentMode ? handleSendComment(input) : sendMessage();
                                        setTimeout(() => {
                                            const textarea = document.querySelector("textarea");
                                            if (textarea) {
                                                textarea.style.height = "auto";
                                            }
                                        }, 0);
                                    }}
                                    className={` border-1 ${(lastMessage && lastMessage.typing) || !input ? ' cursor-not-allowed bg-gray-400 border-gray-400' : 'border-black bg-black'}`}
                                    shape="circle"
                                    icon={<ArrowUpOutlined   className={`text-xl text-white`}/>}
                                />
                            </div>

                            <p className="text-center w-full md:text-sm text-xs text-gray-600">
                                Conseiller d'orientation IA en apprentissage 🚀
                            </p>
                        </div>

                        <div className="  bg-transparent flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0">
                            <div className="md:text-sm text-xs invisible justify-center text-gray-500 order-2 md:order-1 md:flex hidden gap-3">
                                {!isAuthenticated &&(
                                    <>
                                        {/*<Link to="/mentions_legales" className="underline text-gray-500">*/}
                                        {/*    Mentions légales*/}
                                        {/*</Link>*/}
                                        <Link to="/conditions_generales" className="underline text-gray-500">
                                            Conditions générales
                                        </Link>
                                        <Link to="/politique_de_confidentialite" className="underline text-gray-500">
                                            Politique de confidentialité
                                        </Link>
                                    </>
                                    )}
                            </div>

                            <div className="md:text-sm text-xs justify-center text-gray-500 order-1 md:order-2">
                                <button
                                    className="text-blue-500 font-semibold underline text-center"
                                    onClick={() => setIsCommentMode(true)}
                                >
                                    Laisser un commentaire
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginModal visible={isModalVisible} onHide={hideModal}/>
        </LayoutNo>
    );
}

