import { useAxios } from './caller.service';

export const useChatService = () => {
    const AxiosInstance = useAxios();


    let getChatQuestions = (connected, questionId) => {
        return AxiosInstance.get(`/chat_questions?connected=${connected}&question_id=${questionId}`);
    };


    let getRandomResponse = () => {
        return AxiosInstance.get('/random_response/');
    };

    let saveComment = (comment, userId) => {
        return AxiosInstance.post("/comments", { comment,user_id: userId });
    };

    let saveNonAuthMessage = (question) => {
        return AxiosInstance.post("/save_note", { question });
    };

    return {
        getChatQuestions,
        getRandomResponse,saveComment,saveNonAuthMessage
    }
}
