import { useAxios } from './caller.service';

export const useGuidancesService = () => {
    const AxiosInstance = useAxios();


    let getIKISubjects = (subject_search = null, category = null) => {
    let params = {};
    
    if (subject_search !== null) {
        params.subject_search = subject_search;
    }

    if (category !== null) {
        params.category = category;
    }
    
    return AxiosInstance.get('/IKI_subjects/', { params: params });
}

let addQuestions = async (userId, responses, subjectsLevel) => {
    const requestBody = {
        user_id: userId,
        questions_scores: responses.map(response => ({
            question_id: response.questionId,
            score: response.score
        })),
        subjects_level: subjectsLevel
    };

    try {
        const response_1 = await AxiosInstance.post('/UserSubmission/', requestBody);
        // console.log('Data submitted successfully:', response_1.data);
    } catch (error) {
        console.error('Error submitting data:', error);
    }
};

    const getChatbotResponse = async (userId, message) => {
        const requestBody = {
            user_id: userId,
            message: message
        };

        try {
            const response = await AxiosInstance.post("/chatbot", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error contacting the chatbot:", error);
            throw error;
        }
    };

return {
    getIKISubjects, addQuestions,getChatbotResponse
}
}
