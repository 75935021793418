// src/utils/routes/PublicRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../services/auth.provider";

function PublicRoute({ children }) {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? <Navigate to="/" replace /> : children;
}

export default PublicRoute;
