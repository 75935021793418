import React, { useState } from 'react';
import HeartIcon from '../images/heart.svg';
import FilledHeartIcon from '../images/heart-filled.svg';
import {useNavigate} from "react-router-dom";
import { CryptoService } from '../services/crypte.service';
import {useAuth} from "../services/auth.provider";
import LoginModal from "../components/modal/auth/LoginModal";
import {useUserService} from "../services/userforms.service";
import HeartfilledIcon from "../images/heart-filled.svg";

const MetierCard = ({
    imgSrc,
    smtitle,
    title,
    chips,
    frompriceDebutant,
    topriceDebutant,
    frompriceExp,
    topriceExp,
    professionId,
    saveProfession,
    deleteProfession,
    saved,
    userId, isSingleRow

}) => {
    const [isSaved, setIsSaved] = useState(saved !==0);
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { saveUserDataSync,saveUserClickData } = useUserService();



    const handleHeartClick = async () => {
        if (!isAuthenticated) {
            showModal();
            return;
        }

        if (isSaved) {
            try {
                await deleteProfession(professionId);
                saveUserClickData({
                    idMetier: professionId,
                    userId,
                    enregistrer: false,
                });
                setIsSaved(false);
            } catch (error) {
                console.error('Error deleting profession:', error);
            }
        } else {
            try {
                await saveProfession(professionId);
                saveUserClickData({
                    idMetier: professionId,
                    userId,
                    enregistrer: true,
                });
                setIsSaved(true);
            } catch (error) {
                console.error('Error saving profession:', error);
            }
        }
    };

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const goToDetailPage = () => {
        const professionName = CryptoService.nameUrlFormat(title.toString())
        if(isAuthenticated){
            saveUserDataSync("saveRecherche","formMetiersSearchData","METIER", userId);
            saveUserClickData({
                idMetier: professionId,
                userId,
                enregistrer: isSaved,
            });
        }

        navigate(`/metiers/${professionName}/${professionId}`);
    };


    return (
        <>
            {isSingleRow && (
                    <div className=" hidden md:flex bg-white rounded-xl shadow-lg p-2  items-center py-3">
                        <img src={imgSrc} alt="logo" className="w-[70px] h-15 p-2  border-2 rounded-xl mr-3" onClick={goToDetailPage} />
                        <div className="flex-1">
                            <div>
                                <a
                                    href={`/metiers/${CryptoService.nameUrlFormat(title.toString())}/${professionId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center space-x-2"
                                    onClick={(e) => {
                                        if (e.button === 0) {
                                            e.preventDefault();
                                            goToDetailPage();
                                        }
                                    }}
                                >
                                    <p
                                        className="font-inter  text-[13px] text-indigo-800 font-inter line-clamp-2  truncate-text"
                                        title={smtitle}
                                    >
                                        {smtitle}
                                    </p>
                                </a>
                            </div>
                            <div >
                                <a
                                    href={`/metiers/${CryptoService.nameUrlFormat(title.toString())}/${professionId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center space-x-2"
                                    onClick={(e) => {
                                        if (e.button === 0) {
                                            e.preventDefault();
                                            goToDetailPage();
                                        }
                                    }}
                                >

                                    <p className="font-bold   text-[16px] text-indigo-800   line-clamp-2 "
                                       title={title}>{title}</p>
                                </a>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {chips.map((chip, idx) => (
                                    <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-indigo-800 rounded-full flex items-center">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                        <path d="M0.5 6L8 2L15.5 6L8 10L0.5 6Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.75 15V8L8 6" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13.75 6.93359V10.3411C13.7502 10.449 13.7153 10.554 13.6507 10.6403C13.2296 11.2006 11.4533 13.2503 8 13.2503C4.54669 13.2503 2.77039 11.2006 2.34935 10.6403C2.28466 10.554 2.24979 10.449 2.25 10.3411V6.93359" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                        {chip}
                                </span>
                                ))}
                                <span className=" p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                         <path
                                             d="M13.5 4.5H2.5C2.22386 4.5 2 4.72386 2 5V13C2 13.2761 2.22386 13.5 2.5 13.5H13.5C13.7761 13.5 14 13.2761 14 13V5C14 4.72386 13.7761 4.5 13.5 4.5Z"
                                             stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                         <path d="M5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C8.66304 2 9.29893 2.26339 9.76777 2.73223C10.2366 3.20107 10.5 3.83696 10.5 4.5" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                     </svg>
                                <span className="text-xs text-[#1A3866] font-medium">Débutant :</span>
                                <span className="text-xs font-medium text-[#1A3866]">
                                    {frompriceDebutant.toLocaleString('fr-FR')} € - {topriceDebutant.toLocaleString('fr-FR')} €
                                </span>
                            </span>
                                <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                         <path d="M13.5 4.5H2.5C2.22386 4.5 2 4.72386 2 5V13C2 13.2761 2.22386 13.5 2.5 13.5H13.5C13.7761 13.5 14 13.2761 14 13V5C14 4.72386 13.7761 4.5 13.5 4.5Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                         <path d="M5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C8.66304 2 9.29893 2.26339 9.76777 2.73223C10.2366 3.20107 10.5 3.83696 10.5 4.5" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                     </svg>
                                <span className="text-xs text-[#1A3866] font-medium">Expérimenté :</span>
                                <span className="text-xs font-medium text-[#1A3866]">
                                    {frompriceExp.toLocaleString('fr-FR')} € - {topriceExp.toLocaleString('fr-FR')} €
                                </span>
                            </span>
                            </div>
                        </div>
                        <button onClick={handleHeartClick} className="ml-auto">
                            <img
                                src={!isAuthenticated || !isSaved ? HeartIcon : FilledHeartIcon}
                                alt={isSaved ? "remove from favorites" : "add to favorites"}
                                className="w-8 h-8" />
                        </button>
                    </div>
            )}

            <div className={`${isSingleRow ? 'md:hidden' : ''}`}>
                <div className="bg-white border-1 border-[#D0DBE7]  rounded-md shadow-lg p-2 relative cursor-pointer" >
                    <div className="flex justify-between items-start">
                        <div className="flex items-center space-x-2" >
                            <img src={imgSrc} alt="logo" className="max-w-12 p-1" onClick={goToDetailPage} />
                            <div>
                                <a
                                    href={`/metiers/${CryptoService.nameUrlFormat(title.toString())}/${professionId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center space-x-2"
                                    onClick={(e) => {
                                        if (e.button === 0) {
                                            e.preventDefault();
                                            goToDetailPage();
                                        }
                                    }}
                                >
                                    <p className="font-inter text-[14px] font-medium leading-[17.5px] text-[#434769] text-left  max-h-[20px]">
                                        {smtitle || ''}
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div className="flex space-x-1 justify-end">

                            <button onClick={handleHeartClick}>
                                <img
                                    src={!isAuthenticated || !isSaved ? HeartIcon : FilledHeartIcon}
                                    // src={isSaved ? FilledHeartIcon : HeartIcon}
                                    alt={isSaved ? "remove from favorites" : "add to favorites"}
                                    className="object-cover min-w-6"
                                />
                            </button>
                        </div>
                    </div>
                    <div>
                        <a
                            href={`/metiers/${CryptoService.nameUrlFormat(title.toString())}/${professionId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center space-x-2"
                            onClick={(e) => {
                                if (e.button === 0) {
                                    e.preventDefault();
                                    goToDetailPage();
                                }
                            }}
                        >

                            {/*<p className="font-inter text-md font-bold text-[#1A3866] mt-4 min-h-[35px] cursor-pointer" >{title || ''}</p>*/}
                            <p
                                className="font-inter text-md font-bold mt-4  text-[15px] text-[#141943]  line-clamp-2 min-h-[36px] truncate-text cursor-pointe"
                                title={title}
                            >
                                {title}
                            </p>
                        </a>
                        <hr className="my-2" />
                        <div className="flex flex-wrap gap-2">
                            {chips.map((chip, idx) => (
                                <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full flex items-center">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                            <path d="M0.5 6L8 2L15.5 6L8 10L0.5 6Z" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.75 15V8L8 6" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.75 6.93359V10.3411C13.7502 10.449 13.7153 10.554 13.6507 10.6403C13.2296 11.2006 11.4533 13.2503 8 13.2503C4.54669 13.2503 2.77039 11.2006 2.34935 10.6403C2.28466 10.554 2.24979 10.449 2.25 10.3411V6.93359" stroke="#434769" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                                    {chip}

                    </span>
                            ))}
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-xs text-[#1A3866] font-medium">Débutant :</p>
                            <p className="text-xs font-medium text-gray-600">        {frompriceDebutant.toLocaleString('fr-FR')} € - {topriceDebutant.toLocaleString('fr-FR')} €</p>
                        </div>
                        <div className="flex justify-between items-center ">
                            <p className="text-xs text-[#1A3866] font-medium">Expérimenté :</p>
                            <p className="text-xs font-medium text-gray-600">{frompriceExp.toLocaleString('fr-FR')} € - {topriceExp.toLocaleString('fr-FR')} €
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <LoginModal visible={isModalVisible} onHide={hideModal}   />
        </>
    );
};

export default MetierCard;
