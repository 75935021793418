import React, { useCallback, useEffect, useState,useRef } from "react";
import CustomCard from "../components/search/CustomCard";
import { useUserService } from "../services/userforms.service";
import { useGuidancesService } from "../services/guidance.service";
import RatingModal from "../components/modal/RatingModal";
import CategoriesNoAuth from "../components/search/CategorieNoAuth";
import ArrowUp from "../images/arrow-up.svg";
import CustomSelect from "../components/dropDown/CustomSelect";
import { useProgress } from '../context/ProgressContext';
import ArrowDown from "../images/arrow-down.svg";
import { useSubmissionService } from "../services/submission.service";
import DropDownMobile from "../components/dropDown/DropDownMobile";
import '../style/sujets-preferes.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../services/auth.provider';
import LayoutNo from "../utils/LayoutNo";
import { CryptoService } from "../services/crypte.service";
import { Modal, Progress, notification, Alert} from 'antd';
import {Helmet} from "react-helmet";

export default function SujetsPreferes() {
    const [categoriesWithSubjects, setCategoriesWithSubjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCards, setSelectedCards] = useState([]);
    const [multiSelectOptions, setMultiSelectOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [rating, setRating] = useState({});
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isMobileCategoriesOpen, setIsMobileCategoriesOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const { setFinalSectionProgress } = useProgress();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCategories, setFilteredCategories] = useState(categoriesWithSubjects);
    const [tempSelectedCard, setTempSelectedCard] = useState(null);
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const { isAuthenticated } = useAuth();
    const scrollableSectionRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { getUserSubmissionData, userSubmission } = useSubmissionService();
    const { getSubjectsCategries } = useUserService();
    const { getIKISubjects } = useGuidancesService();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0); // Progression de la barre

    useEffect(() => {
        const selectedCategories = JSON.parse(localStorage.getItem("selectedCategories")) || [];
        if (selectedCategories.length === 0) {
            setIsModalVisible(true);
        }
    }, []);


    // Detect screen size
    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsSmallScreen(window.innerWidth < 768);
    //     };
    //
    //     handleResize(); // Set initial value
    //     window.addEventListener("resize", handleResize);
    //
    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //     };
    // }, []);

    useEffect(() => {

        if (isAuthenticated) {
            const user = localStorage.getItem('user_id');
            const decryptedId = CryptoService.decryptData(user)
            setUserId(decryptedId);
        }

        setFinalSectionProgress(parseInt(93, 10));

        fetchCategoriesAndSubjects();

        // document.body.classList.add("overflow-y-hidden");
        // return () => {
        //     document.body.classList.remove("overflow-y-hidden");
        // };
    }, []);

    useEffect(() => {
        fetchUserSubmissionData();
    }, [isAuthenticated, userId]);


    const fetchUserSubmissionData = async () => {
        if (isAuthenticated && userId) {
            // console.log(userId, "userId");
            try {
                const response = await getUserSubmissionData(parseInt(userId));
                if (response?.data?.status === 'success') {
                    const userSubjects = response.data.data.user_subjects;

                    // Sync with local storage
                    syncLocalStorageWithServerData(userSubjects);

                    // Update state with fetched data
                    setSelectedCategories(userSubjects.map(subject => ({
                        id: subject.subject_id,
                        label: subject.sub_name,
                        rating: subject.subject_level,
                    })));
                    setSelectedCards(userSubjects.map(subject => subject.subject_id));
                    setRating(userSubjects.reduce((acc, subject) => {
                        acc[subject.subject_id] = subject.subject_level;
                        return acc;
                    }, {}));
                } else {
                    console.error("Error: Unexpected response format or status");
                }
            } catch (error) {
                console.error("Error fetching submission data: ", error);
            }
        }
    };

    const syncLocalStorageWithServerData = (serverData) => {
        const structuredData = serverData.map(subject => ({
            id: subject.subject_id,
            label: subject.sub_name,
            rating: subject.subject_level,
        }));

        localStorage.setItem('selectedCategories', JSON.stringify(structuredData));
        localStorage.setItem('selectedCards', JSON.stringify(structuredData.map(item => item.id)));

        const ratings = {};
        structuredData.forEach(item => {
            ratings[item.id] = item.rating;
        });
        localStorage.setItem('ratings', JSON.stringify(ratings));
    };

    useEffect(() => {
        localStorage.setItem('selectedCategories', JSON.stringify(selectedCategories));
        localStorage.setItem('selectedCards', JSON.stringify(selectedCards));
    }, [selectedCategories, selectedCards]);

    const fetchCategoriesAndSubjects = useCallback(async (categoryIds = []) => {
        try {
            let response = await getSubjectsCategries();
            if (response && response.data && response.data.data) {
                let categories = response.data.data;

                setMultiSelectOptions(categories);
                // console.log("categoryIds ", categoryIds);

                const filteredCategories = categoryIds.length > 0
                    ? categories.filter(category => categoryIds.includes(category.cat_id))
                    : categories;

                // console.log("filteredCategories ", filteredCategories);

                const fetchSubjectsPromises = filteredCategories.map(async (category) => {
                    let subjectResponse = await getIKISubjects(null, category.cat_id);
                    let subjects = subjectResponse?.data?.data || [];
                    return {
                        category: category.cat_name,
                        subjects: subjects,
                    };
                });

                const results = await Promise.all(fetchSubjectsPromises);
                setCategoriesWithSubjects(results);
                setFilteredCategories(results);

            } else {
                throw new Error("Invalid response structure");
            }
        } catch (error) {
            console.error("Error occurred during fetching process:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);


    const handleDropdownChange = (selected) => {
        setSelectedOption(selected);
        // console.log("fetch ", selected);

        if (scrollableSectionRef.current) {
            scrollableSectionRef.current.scrollTop = 0;
        }

        const selectedIds = selected.map(opt => opt.cat_id);

        // console.log("selectedIds", selectedIds);

        if (selectedIds.length > 0) {
            fetchCategoriesAndSubjects(selectedIds);
        } else {
            fetchCategoriesAndSubjects();
        }
    };


    const handleCardClick = (id, subjectName) => {
        const isAlreadySelected = selectedCards.includes(id);

        if (isAlreadySelected) {
            handleDeleteCategory(id);
        } else {
            if (selectedCategories.length < 7) {
                setTempSelectedCard({ id, label: subjectName });
                openModal();
            } else {
                alert("Vous pouvez sélectionner jusqu'à 7 sujets seulement.");
            }
        }
    };

    // const handleRatingChange = (newRating) => {
    //     if (tempSelectedCard) {
    //         const { id, label } = tempSelectedCard;
    //
    //         setRating(prev => {
    //             const updatedRating = { ...prev, [id]: newRating };
    //             updateLocalStorage(id, label, newRating);
    //             return updatedRating;
    //         });
    //
    //         const updatedSelectedCategories = [...selectedCategories];
    //         const categoryIndex = updatedSelectedCategories.findIndex(cat => cat.id === id);
    //
    //         if (categoryIndex !== -1) {
    //             updatedSelectedCategories[categoryIndex].rating = newRating;
    //         } else {
    //             updatedSelectedCategories.push({ id, label, rating: newRating });
    //         }
    //
    //         setSelectedCategories(updatedSelectedCategories);
    //
    //         if (!selectedCards.includes(id)) {
    //             setSelectedCards(prevSelected => [...prevSelected, id]);
    //         }
    //
    //         setTempSelectedCard(null);
    //     }
    // };

    const handleRatingChange = (newRating) => {
        if (tempSelectedCard) {
            const { id, label } = tempSelectedCard;

            setRating((prev) => {
                const updatedRating = { ...prev, [id]: newRating };
                updateLocalStorage(id, label, newRating);
                return updatedRating;
            });

            // Update selected categories
            const updatedSelectedCategories = [...selectedCategories];
            const categoryIndex = updatedSelectedCategories.findIndex((cat) => cat.id === id);

            if (categoryIndex !== -1) {
                updatedSelectedCategories[categoryIndex].rating = newRating;
            } else {
                updatedSelectedCategories.push({ id, label, rating: newRating });
            }

            setSelectedCategories(updatedSelectedCategories);

            if (!selectedCards.includes(id)) {
                setSelectedCards((prevSelected) => [...prevSelected, id]);
            }


            setTimeout(() => {
                setShowModal(false);
                setTempSelectedCard(null);
                setCurrentItem(null);
            }, 500);
        }
    };



    const updateLocalStorage = (itemId, itemName, newRating) => {
        const storedCategories = JSON.parse(localStorage.getItem('selectedCategories')) || [];
        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};

        const updatedCategories = [...storedCategories.filter(cat => cat.id !== itemId), { id: itemId, label: itemName, rating: newRating }];
        localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));

        storedRatings[itemId] = parseInt(newRating);
        localStorage.setItem('ratings', JSON.stringify(storedRatings));
    };


    const handleDeleteCategory = (id) => {
        // console.log(id, "handleDeleteCategory");
        setSelectedCategories(prev => prev.filter(cat => cat.id !== id));
        setSelectedCards(prevSelected => prevSelected.filter(cardId => cardId !== id));

        const storedCategories = JSON.parse(localStorage.getItem('selectedCategories')) || [];
        const updatedCategories = storedCategories.filter(cat => cat.id !== id);
        localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));

        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};
        delete storedRatings[id];
        localStorage.setItem('ratings', JSON.stringify(storedRatings));
    };



    const openModal = () => setShowModal(true);

    // const closeModal = () => {
    //     setShowModal(false);
    //     setTempSelectedCard(null);
    //     setCurrentItem(null);
    // };

    const closeModal = () => {
        setTimeout(() => {
            setShowModal(false);
            setTempSelectedCard(null);
            setCurrentItem(null);
        }, 1000);
    };


    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
            </div>
        );
    }

    if (error) {
        return (
            <>
                <div className="flex justify-center items-center h-screen">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
                </div>
                Error: {error}
            </>
        );
    }

    const toggleMobileCategoriesOpen = () => {
        setIsMobileCategoriesOpen(!isMobileCategoriesOpen);
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchTerm(query);

        const filtered = categoriesWithSubjects.filter(category => {
            const matchesCategory = category.category.toLowerCase().includes(query);

            const matchesSubjects = category.subjects.some(subject => {
                const subjectName = subject?.sub_name ? subject?.sub_name.toLowerCase() : '';
                const subjectDescription = subject.sub_description ? subject.sub_description.toLowerCase() : '';
                return subjectName.includes(query) || subjectDescription.includes(query);
            });

            return matchesCategory || matchesSubjects;
        });

        // window.scrollTo(0, 0);
        if (scrollableSectionRef.current) {
            scrollableSectionRef.current.scrollTop = 0;  // Manually scroll to the top of the section
        }
        setFilteredCategories(filtered);
    };

    const submitUserData = async () => {
        const subjectsSelectedData = JSON.parse(localStorage.getItem('ratings')) || {};

        if (Object.keys(subjectsSelectedData).length < 5) {
            notification.warning({
                message: '',
                description: 'Veuillez choisir au moins 5 sujets',
                placement: 'topRight',
            });
            return;
        }

        try {
            if (isAuthenticated) {
                setIsSubmitLoading(true);
                setIsProcessing(true);
    
                let currentProgress = 0;
    
                const interval = setInterval(() => {
                    currentProgress += 1;
                    setProgress(currentProgress);
                
                    if (currentProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => {
                            setIsProcessing(false);
                            navigate("/orientation");
                        }, 1000);
                    }
                }, 100);
    
                const response = await userSubmission(parseInt(userId));
    
                if (response && response.status !== "success") {
                    notification.error({
                        message: 'Maintenance en cours',
                        description: 'Le système est actuellement en maintenance. Veuillez réessayer plus tard.',
                        placement: 'topRight',
                    });
                }
        
            } else {
                navigate("/public/inscription");
            }
        } catch (error) {
            console.error("Erreur lors de l'appel API :", error);
            notification.error({
                message: 'Maintenance en cours',
                description: 'Le système est actuellement en maintenance. Veuillez réessayer plus tard.',
                placement: 'topRight',
            });
        } finally {
            setIsSubmitLoading(false);
        }
    };


    return (
        <LayoutNo>
            <Helmet>
                <title>Linkyways</title>
            </Helmet>
            <div className="flex flex-col h-screen bg-white md:mt-10 mt-20  p-1">


                <div className="flex flex-col lg:flex-row  space-y-4 md:space-y-0 md:space-x-1 mt-3  ">

                    <div className="w-full md:w-1/2 bg-white  rounded-lg flex items-center justify-center ">

                        <div className="bg-white p-2 flex flex-col flex-grow ">

                            <header>
                                {/*<Button*/}
                                {/*    icon={<LeftOutlined  className={"text-blue-500    text-lg"}/>}*/}
                                {/*    onClick={() => navigate(-1)}*/}
                                {/*    className="  -mt-3 mb-2 shadow-lg bg-white backdrop-blur-lg border-0 rounded-full  "*/}
                                {/*    shape="circle"*/}
                                {/*/>*/}

                                <Alert
                                    message="Choisissez entre 5 et 7 sujets. Vous pourrez revenir facilement à cet écran pour essayer d'autres combinaisons."
                                    type="warning"
                                    className="mb-2 mt-2"
                                    closable
                                    onClose={() => setIsModalVisible(false)}
                                    showIcon
                                />
                                <div className="hidden lg:flex lg:flex-row items-center mb-4 space-x-4">
                                    <div
                                        className="flex items-center bg-[#E2E9EF] rounded-lg shadow-sm border border-gray-200 lg:w-[70%]">
                                        <svg
                                            className="w-1 h-4 text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M21 21l-4.35-4.35M4 10.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z"
                                            ></path>
                                        </svg>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            className="bg-transparent py-2.5 w-full text-sm focus:outline-none"
                                            placeholder="Recherche par mot-clé"
                                            style={{ fontSize: '16px' }}

                                        />
                                    </div>

                                    <div className="lg:w-[30%]">
                                        <CustomSelect
                                            options={multiSelectOptions}
                                            onChange={handleDropdownChange}
                                        />
                                    </div>
                                </div>
                                {/* Search and Dropdown for Mobile */}
                                <div className="lg:hidden w-full flex items-center space-x-4 mb-4">
                                    <input
                                        style={{ fontSize: '16px' }}
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="w-full bg-[#E2E9EF] px-5 py-2.5 block border-gray-200 rounded-lg "
                                        placeholder="Recherche par mot-clé"
                                        spellCheck={false}
                                    />
                                    <div className="relative ">
                                        <DropDownMobile
                                            options={multiSelectOptions}
                                            onChange={handleDropdownChange}
                                        />
                                    </div>
                                </div>
                                {/* Mobile Categories Toggle */}
                                {selectedCategories.length >0 &&(
                                <div className="lg:hidden mb-2">
                                    <button
                                        className="w-full flex justify-between font-poppins bg-white text-black border rounded-lg p-2"
                                        onClick={toggleMobileCategoriesOpen}
                                    >
                                        <p className="p-0 m-0">
                                            Sélectionnés
                                            <span className="text-gray-400 ml-2 ">
                                                {selectedCategories.length}/7
                                            </span>
                                        </p>
                                        <img
                                            src={isMobileCategoriesOpen ? ArrowUp : ArrowDown}
                                            alt={isMobileCategoriesOpen ? "ArrowUp" : "ArrowDown"}
                                            className=" cursor-pointer"
                                        />
                                    </button>
                                    {isMobileCategoriesOpen && (
                                        <div className="bg-white rounded-lg border mt-2">
                                            <CategoriesNoAuth
                                                items={selectedCategories}
                                                onDelete={handleDeleteCategory}
                                            />
                                        </div>
                                    )}
                                </div>
                                    )}

                            </header>
                            <section className="flex-grow custom-scrollbar  ">
                                <div className="overflow-y-auto h-[calc(100vh-160px)] px-2 pb-32" ref={scrollableSectionRef}>
                                    {filteredCategories.map(({ category, subjects }) => (
                                        <div key={category} className="category-section mb-4">
                                            <h2 className="font-poppins text-lg text-[#141943] mt-1 mb-4">{category}</h2>
                                            <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mb-10">
                                                {subjects.map((subject) => (
                                                    <CustomCard
                                                        key={subject.sub_id}
                                                        title={subject?.sub_name}
                                                        description={subject.sub_description}
                                                        image={subject.sub_url}
                                                        checked={selectedCards && selectedCards.includes(subject.sub_id)}
                                                        onClick={() => handleCardClick(subject.sub_id, subject?.sub_name)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        </div>

                    </div>

                    <div className="w-1/3 bg-transparent  rounded-lg  items-center justify-center">
                        <div className="   rounded-lg border-2 border-[#D0DBE7]  w-full overflow-y-auto rounded-lg shadow-md ">
                            <div className="space-y-2  w-full space-y-5">
                                <CategoriesNoAuth
                                    items={selectedCategories}
                                    onDelete={handleDeleteCategory}
                                />
                                {selectedCategories.length >=5 && (
                                <div className="px-4">
                                    <button
                                        type="submit"
                                        className="bg-blue-500 text-white font-semibold font-poppins py-2 px-4 w-full rounded-lg  mb-4"
                                        onClick={submitUserData}
                                        disabled={isSubmitLoading}
                                    >
                                        {isSubmitLoading ? 'Chargement...' : 'Suivant'}
                                    </button>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Fixed Button for Mobile */}
                <div className="fixed bottom-0 inset-x-0 bg-white shadow-lg p-2 lg:hidden">
                    <button
                        type="submit"
                        className="bg-[#1681FF] text-white rounded-lg py-2 px-6 w-full"
                        onClick={submitUserData}
                        disabled={isSubmitLoading}
                    >
                        {isSubmitLoading ? 'Chargement...' : 'Suivant'}
                    </button>
                </div>
            </div>

            {showModal && tempSelectedCard && (
                <RatingModal
                    visible={showModal}
                    item={tempSelectedCard}
                    rating={rating[tempSelectedCard.id]}
                    onClose={closeModal}
                    onRatingChange={handleRatingChange}
                />
            )}


            <Modal
                // title={
                //     <div className="flex justify-center ">
                //         <p className="text-[#20588D] font-poppins text-lg font-bold ">
                //             Sujets préférés
                //         </p>
                //     </div>}
                visible={isProcessing}
                closable={false}
                footer={
                    <div className="flex justify-center mt-5">
                        <p className="font-poppins font-semibold" > Veuillez patienter pendant le calcul...</p>
                    </div>
                }
            >
                <ul className="list-disc ml-2 -mt-2 px-2 ">
                    <p className="mb-2"> Nous analysons vos résultats et vous orientons vers les métiers les plus adaptés.</p>
                    <center>
                        <Progress percent={progress} type="circle" />
                    </center>
                </ul>

            </Modal>
        </LayoutNo>
    );

}
