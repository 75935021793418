import LayoutNo from "../LayoutNo";

export default function AproposSkeleton(){
    return(
            <LayoutNo>
                <div className="flex flex-col md:flex-row gap-4 p-3 md:mt-1 mt-6">
                    <div className=" w-full space-y-2">
                        {Array(6)
                            .fill(null)
                            .map((_, index) => (
                                <div
                                    key={index}
                                    className="border-1 bg-gray-100 rounded-md border-gray-100 p-3 animate-pulse"
                                >
                                    <div className="h-6 bg-gray-200 rounded mb-2"></div>
                                    <div className="h-4 bg-gray-200 rounded"></div>
                                </div>
                            ))}
                    </div>
                </div>
            </LayoutNo>
        );
    }
