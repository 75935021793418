import React from "react";
import LayoutNo from "../../utils/LayoutNo";

export default function PolitiqueDeConfidentialite() {
    return (
        <LayoutNo>
            <div className="p-2 md:mx-24 mx-5 md:mt-1 mt-16">
                <h1 className="text-3xl font-bold text-blue-600 ">
                    Politique de confidentialité
                </h1>
                {/*<h3 className="text-3xl  text-blue-600 my-4">*/}
                {/*    qui représentent, pour nous, un gage de sérieux et de confiance.*/}
                {/*</h3>*/}
                <h3 className="text-3xl  text-blue-600 my-4">
                    Quelle est l’utilité de notre Politique de confidentialité ?
                </h3>

                <p className="mb-4">
                    Linkyways SASU, qui gère le site <a href="https://www.linkyways.com"
                                                        className="text-blue-500 hover:underline">www.linkyways.com</a>,
                    accorde une grande importance à la protection et la confidentialité de vos données personnelles qui
                    représentent, pour nous, un gage de sérieux et de confiance.
                </p>
                <p className="mb-4">
                    A ce titre, notre Politique de confidentialité des données personnelles témoigne précisément de
                    notre <span className="font-bold">volonté de faire respecter, au sein de Linkyways SASU, les règles applicables en matière de protection des données personnelles </span> et,
                    plus particulièrement, celles du Règlement général sur la protection des Données ("RGPD").
                </p>
                <p className="mb-4">
                    En particulier, notre Politique de confidentialité vise à vous informer sur la manière et les
                    raisons pour lesquelles nous traitons vos données personnelles dans le cadre des services que nous
                    vous fournissons.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    A qui s’adresse notre Politique de confidentialité ?
                </h2>
                <p className="my-4">
                    Notre Politique de confidentialité s’adresse à <span className="font-bold">vous</span>, quel que
                    soit votre lieu de domiciliation, dès lors que vous êtes âgé d'au moins 15 ans, que vous soyez <span
                    className="font-bold"> client, candidat à un poste au sein de Linkyways SASU,</span> ou encore <span
                    className="font-bold">visiteur</span> du site www.linkyways.com.
                </p>
                <p className="mb-4">
                    Si vous avez moins de l'âge légal détaillé ci-dessus, vous n’êtes pas autorisé à utiliser nos
                    services <span className="font-bold">sans le consentement préalable et explicite </span>de l’un de
                    vos parents ou du titulaire de l'autorité parentale qui doit nous être adressé par email à l’adresse
                    dpo@linkyways.com.
                </p>
                <p className="mb-4">
                    Si vous pensez que nous détenons des données personnelles concernant vos enfants sans y avoir
                    consenti, nous vous invitons à nous contacter à l'adresse dédiée détaillée ci-dessus.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Pourquoi traitons-nous vos données personnelles et sur quel fondement ?
                </h2>
                <h3 className=" my-4">
                    Nous traitons vos données personnelles essentiellement pour les raisons suivantes :
                </h3>
                <ul className="list-disc list-inside mb-4 ml-5 text-justify space-y-3">
                    <li><strong> naviguer sur notre site internet, bénéficier de nos services</strong> et pour que nous puissions <strong>répondre à vos demandes</strong> (ex : demandes d’information, réclamations, etc.) sur la base de nos conditions générales d’utilisation et de notre intérêt légitime à vous fournir le meilleur service possible.</li>
                    <li><strong> enregistrer les conversations</strong> que nous avons avec vous lors de nos appels de prospection commerciale, sur la base de votre consentement préalable.</li>
                    <li><strong> recevoir notre newsletter</strong> qui vous informe sur toutes les actualités concernant nos services sur la base de notre intérêt légitime à fidéliser notre clientèle.</li>
                    <li><strong> candidater à un poste</strong> au sein de <a href="https://www.linkyways.com" class="text-blue-600 underline">Linkyways SASU</a> sur la base des discussions que nous avons avec vous pendant le processus de recrutement et de notre intérêt légitime à recruter et sélectionner des candidats.</li>
                    <li><strong> prendre rendez-vous</strong> avec nos équipes sur la base de notre intérêt légitime à vous offrir un moyen de prendre facilement rendez-vous avec nous.</li>
                    <li><strong> permettre de vous faire participer à nos jeux concours</strong>, sur la base de notre intérêt légitime à les organiser et de l’exécution du règlement du jeu associé.</li>
                </ul>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Comment avons-nous obtenu vos données personnelles ?
                </h2>
                <p className="my-4">
                    Vos données sont collectées <span className="font-bold">directement auprès de vous</span> dès
                    lors que vous êtes utilisateur de nos services ou "simple" visiteur de notre site www.linkyways.com
                    et nous nous engageons à ne traiter vos données que pour les <span className="font-bold">raisons décrites précédemment.</span>
                </p>
                <p className="mb-4">
                    Concernant les professionnels uniquement, il est également possible que vos données personnelles
                    soient traitées indirectement dans le cadre de salons ou de réseaux sociaux (ex : Linkedin).
                </p>
                <p className="mb-4">
                    En revanche, dès lors que vous publiez volontairement du contenu sur les pages que nous éditons
                    sur les réseaux sociaux, vous reconnaissez être <span
                    className="font-bold">entièrement responsable</span> de
                    toute information personnelle que vous pourriez transmettre, quelle que soit la nature et
                    l’origine de l’information fournie.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Quelles données personnelles traitons-nous et pour combien de temps ?
                </h2>
                <p className="my-4">
                    Nous avons résumé ci-après les <span
                    className="font-bold">catégories de données personnelles</span> ainsi que leurs <span
                    className="font-bold">durées respectives de conservation :</span>
                </p>
                <ul className="list-disc list-inside space-y-3 ml-5">
                    <li><strong>Pour les particuliers, données d’identification personnelles</strong> (ex : nom, prénom) et coordonnées (ex : adresse email) conservées pendant toute la durée de la fourniture du service à laquelle s’ajoutent les délais de prescriptions légaux qui sont généralement de 5 ans.</li>
                    <li><strong>Pour les professionnels, données d’identification personnelles</strong> (ex : nom, prénom, poste, entreprise, ville, contact) et coordonnées (ex : adresse email et téléphone professionnel) conservées pendant toute la durée de la fourniture du service à laquelle s’ajoutent les délais de prescriptions légaux qui sont généralement de 5 ans.</li>
                    <li><strong>Pour les particuliers, données d’ordre économique et financier</strong> (ex : numéro de compte bancaire, code de vérification, etc.) conservées pendant la durée nécessaire à la transaction et à la gestion de la facturation et des paiements à laquelle s’ajoutent les délais de prescription légaux qui sont généralement de 5 à 10 ans.</li>
                    <li><strong>Pour les professionnels, lorsqu’il y a une confusion entre le nom de votre structure et votre nom personnel</strong> (ex : auto-entrepreneur, TPE, etc.), données d’ordre économique et financier (ex : numéro de compte bancaire, code de vérification, etc.) conservées pendant la durée nécessaire à la transaction et à la gestion de la facturation et des paiements à laquelle s’ajoutent les délais de prescription légaux qui sont généralement de 5 à 10 ans.</li>
                    <li><strong>Numéro de téléphone</strong> dans le cadre de nos campagnes de prospection commerciale par téléphone conservé pendant une durée maximale de 3 ans à compter du dernier contact que nous avons eu avec vous.</li>
                    <li><strong>Voix</strong> conservées pour une durée maximale de 6 mois.</li>
                    <li><strong>Données indiquées dans le CV et la lettre de motivation</strong> conservées pendant la durée du processus de recrutement puis pendant 2 ans à compter de votre candidature.</li>
                    <li><strong>Données de connexion</strong> (ex : logs, adresse IP, etc.) conservées pendant une durée de 1 an.</li>
                    <li><strong>Cookies</strong> qui sont en général conservés pendant une durée de 13 mois maximum. Pour plus de détails sur l’utilisation que nous faisons de vos cookies, vous pouvez consulter notre <a href="#" class="text-blue-600 underline">politique accessible à tout moment</a>.</li>
                </ul>
                <p className="mb-4">
                    A l'expiration des durées de conservation applicables, la suppression de vos données
                    personnelles est <span className="font-bold">irréversible</span> et nous ne pourrons plus vous
                    les communiquer passé ce délai. Tout au plus, nous pouvons uniquement conserver des données
                    anonymes à des fins de statistiques.
                    Tout au plus, nous pouvons uniquement conserver des données anonymes à des fins de <span
                    className="font-bold">statistiques.</span>
                </p>
                <p className="mb-4">
                    Veuillez également noter qu’en cas de <span className="font-bold">contentieux,</span> nous avons
                    pour obligation de conserver <span className="font-bold">l’intégralité</span> des données
                    personnelles vous concernant durant toute la durée de traitement du dossier même après
                    l’expiration de leurs durées de conservation décrites précédemment.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    De quels droits disposez-vous pour contrôler l’usage de vos données personnelles ?
                </h2>
                <p class="my-4">La réglementation applicable vous octroie des droits spécifiques que vous pouvez
                    exercer à tout moment et gratuitement pour contrôler l’usage de vos données.
                </p>
                <ul className="list-disc list-inside space-y-3 ml-5">
                    <li><strong>Droit d’accès et de copie</strong> de vos données personnelles dès lors que cette demande n’est pas en contradiction avec le secret des affaires, la confidentialité, ou encore le secret des correspondances.</li>
                    <li><strong>Droit de rectification</strong> de données personnelles qui seraient erronées, obsolètes ou incomplètes.</li>
                    <li><strong>Droit de vous opposer</strong> aux traitements de vos données personnelles mis en œuvre à des fins de prospection commerciale.</li>
                    <li>Pour les consommateurs uniquement, <strong>droit de vous inscrire gratuitement</strong> sur la liste d’opposition au démarchage téléphonique Bloctel à l’adresse <a href="https://www.bloctel.gouv.fr" class="text-blue-600 underline">www.bloctel.gouv.fr</a>.</li>
                    <li><strong>Droit de demander l’effacement</strong> (“droit à l’oubli”) de vos données personnelles qui ne seraient pas essentielles au bon fonctionnement de nos services.</li>
                    <li><strong>Droit à la limitation</strong> de vos données personnelles qui permet de photographier l’utilisation de vos données en cas de contestation sur la légitimité d’un traitement.</li>
                    <li><strong>Droit à la portabilité</strong> de vos données qui vous permet de récupérer une partie de vos données personnelles afin de les stocker ou les transmettre facilement d’un système d’information à un autre.</li>
                    <li><strong>Droit de donner des directives</strong> sur le sort de vos données en cas de décès soit par votre intermédiaire, soit par l’intermédiaire d’un tiers de confiance ou d’un ayant-droit.</li>
                </ul>
                <p class="mt-4">Pour exercer vos droits, adressez votre demande à <a href="mailto:dpo@linkyways.com"
                                                                                     class="text-blue-600 underline">dpo@linkyways.com</a>.
                    Les demandes doivent être faites par vous-même et peuvent nécessiter une preuve d’identité en
                    cas de doute.</p>
                <p class="mt-2">Nous répondrons dans les meilleurs délais, avec un délai maximum de trois mois en
                    cas de complexité technique ou forte affluence de demandes.</p>
                <p class="mt-2">Nous nous réservons le droit de refuser toute demande excessive ou infondée,
                    notamment en cas de caractère répétitif.</p>

                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Qui peut avoir accès à vos données personnelles ?
                </h2>
                <p className="my-4">
                    Vos données personnelles sont traitées par <span className="font-bold">nos équipes et par nos prestataires techniques dans l'unique but de faire fonctionner notre service.</span>
                </p>
                <p className="mb-4">
                    Nous précisons que nous <span className="font-bold">contrôlons tous nos prestataires techniques avant de les recruter</span> afin
                    de s'assurer qu'ils respectent scrupuleusement les règles applicables en matière de protection
                    des données personnelles.
                </p>
                <p className="mb-4">
                    En revanche, nous pouvons, avec votre consentement préalable uniquement, transmettre vos données
                    personnelles à des partenaires. Vous pouvez retirer ce consentement à tout moment en contactant
                    notre DPO à l'adresse dpo@linkyways.com.
                </p>

                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Vos données personnelles peuvent-elles être transférées en dehors de l'Union européenne ?
                </h2>
                <p className="my-4">
                    Les données personnelles traitées par notre site internet sont exclusivement hébergées sur des
                    serveurs situés au sein de l'Union européenne.
                </p>
                <p className="mb-4">
                    Par ailleurs, nous faisons notre maximum pour n'utiliser que des outils techniques dont les
                    serveurs sont également situés au sein de l’Union européenne. Si toutefois cela n'était pas le
                    cas, nous veillons scrupuleusement à ce qu’ils mettent en œuvre les <span className="font-bold">garanties appropriées requises pour assurer la confidentialité et la protection de vos données personnelles.</span>
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Comment protégeons-nous vos données personnelles ?
                </h2>
                <p className="my-4">
                    Nous mettons en œuvre tous les <span className="font-bold">moyens techniques et organisationnels requis</span> pour
                    garantir <span className="font-bold">la sécurité</span> de vos données personnelles au quotidien
                    et, en particulier, pour lutter contre tout risque de destruction, perte, altération, ou
                    divulgation.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Utilisons-nous des cookies lorsque vous naviguez sur notre site internet ?
                </h2>
                <p className="my-4 font-bold">
                    NOUS VOUS GARANTISSONS QUE NOUS N'UTILISONS AUCUN COOKIE PUBLICITAIRE POUR LE FONCTIONNEMENT DE
                    CE SITE. </p>
                <p className="mb-4">
                    En revanche, nous vous informons que nous utilisons des cookies statistiques lorsque vous
                    naviguez sur notre site internet. Pour plus d'informations, nous vous invitons à consulter notre
                    Politique cookies.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Qui pouvez-vous contacter pour obtenir plus d’informations sur l'utilisation de vos données
                    personnelles ?
                </h2>
                <p className="my-4 ">
                    Pour garantir au mieux la protection et l'intégrité de vos données, nous avons officiellement
                    nommé un <span
                    className="font-bold">Délégué à la protection des données (“DPO”) indépendant</span> auprès
                    notre autorité de contrôle.
                </p>
                <p className="mb-4">
                    Vous pouvez à tout moment et gratuitement contacter notre DPO à l'adresse dpo@linkyways.com afin
                    d'obtenir plus d'informations ou de détails sur la manière dont nous traitons vos données.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 ">
                    Comment pouvez-vous contacter la CNIL ?
                </h2>
                <p className="my-4 ">
                    Vous pouvez à tout moment contacter la <span className="font-bold">“Commission nationale de l'informatique et des libertés”</span>
                    ou <span className="font-bold">“CNIL”</span> aux coordonnées suivantes : Service des plaintes de la CNIL, 3 place de Fontenoy – TSA
                    80751, 75334 Paris Cedex 07 ou par téléphone au 01.53.73.22.22.
                </p>
                <h2 className="text-2xl font-semibold text-blue-600 mt-6 mb-2">
                    La Politique de confidentialité peut-elle être modifiée ?
                </h2>
                <p className="my-4 ">
                    Nous sommes susceptibles de modifier notre Politique de confidentialité à <span
                    className="font-bold">tout moment</span> pour l’adapter aux nouvelles <span
                    className="font-bold">exigences légales</span> ainsi qu’aux <span className="font-bold">nouveaux traitements</span> que
                    nous pourrions mettre en œuvre dans le futur.
                </p>
                <p className="my-4 italic ">
                    Certifié conforme par Dipeeo ®
                </p>

            </div>
        </LayoutNo>
    )
}