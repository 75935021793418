import React from "react";
import UnCheckedIcon from "../../images/search/unchecked";
import CheckedIcon from "../../images/search/checked";

export default function CustomCard({ title, description, image, checked, onClick }) {
    return (
        <div
            className={`relative border rounded-lg pt-3 pr-3 pl-3 pb-1 shadow-md cursor-pointer transition-colors duration-200 w-full ${checked ? 'bg-[#FAF2D3] border-black' : 'bg-white border-[#D0DBE7]'}`}
            onClick={onClick}
        >
            <div className="flex justify-between items-start">
                <img
                    src={image}
                    alt={title}
                    className="h-14  object-fit"
                />
                <div className="item-end p-1">
                    {checked ? (
                        <CheckedIcon/>
                    ) : (
                        <UnCheckedIcon/>
                        )}
                </div>
            </div>
            {/* Title and Description */}
            <div className="mt-3">
                <h3 className="font-poppins text-base text-gray-800">{title}</h3>
                <p className="font-inter text-sm text-gray-500 mt-2">{description}</p>
            </div>
        </div>
    );
}
