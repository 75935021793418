import React from "react";
import { useAuth } from "../../services/auth.provider";
import {useState} from "react";
import LoginModal from "../modal/auth/LoginModal";

const CustomCheckbox = ({ label, isChecked, onToggle, trackingID}) => {
    const { isAuthenticated } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);

    let attempts = parseInt(localStorage.getItem("publicApiAttempts") || "0", 10);
    let lastAttemptTime = localStorage.getItem("lastAttemptTime");

    const currentTime = Date.now();
    const oneHour = 60 * 60 * 1000;

    if (lastAttemptTime && currentTime - parseInt(lastAttemptTime, 10) >= oneHour) {
        localStorage.setItem("publicApiAttempts", "0");
        localStorage.setItem("lastAttemptTime", currentTime.toString());
        attempts = 0;
    }
    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);


    const handleClick = () => {
        if (!isAuthenticated && attempts >=10) {
            showModal();
            return;
        }
        onToggle();
    };

    return (
        <>
        <div
            data-tracking={trackingID}
            className="flex items-center border rounded-md bg-white shadow-md w-full border-[#D0DBE7] md:p-1 p-2 cursor-pointer"
            onClick={handleClick}
        >
            <div className="ml-1">
                {isChecked === "1" ? (
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 min-w-4">
                        <rect y="0.5" width="16" height="16" rx="3" fill="#141943" />
                        <path d="M11.4375 6.625L6.85414 11L4.5625 8.8125" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                ) : (
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 min-w-4 ">
                        <rect x="0.5" y="1" width="15" height="15" rx="2.5" stroke="#141943" />
                    </svg>
                )}
            </div>
            <span className="text-[14px]  font-medium font-inter text-black">{label}</span>
        </div>
    <LoginModal visible={isModalVisible} onHide={hideModal}  />

    </>
);
};

export default CustomCheckbox;
