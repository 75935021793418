import React, { useEffect, useState } from "react";
import LayoutNo from "../utils/LayoutNo";
import GradientBarWithArrow from "../utils/GradiantBarWithArrow";
import tooltipSvg from "../images/search/tooltipSvg.svg";
import HtmlTooltip from "../utils/HtmlTooltip";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useMetierService } from "../services/metier.service";
import HeartIcon from '../images/heart.svg';
import FilledHeartIcon from '../images/heart-filled.svg';
import { useItemsService } from "../services/ items.service";
import LoginModal from "../components/modal/auth/LoginModal";
import { CryptoService } from "../services/crypte.service";
import CircularProgress from "@mui/material/CircularProgress";
import {Button, Modal, notification} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import {useAuth} from "../services/auth.provider";
import {useUserService} from "../services/userforms.service";
import {Helmet} from "react-helmet";
import logo from "../images/logo.svg";
export default function MetierDetailPage() {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [profession, setProfession] = useState(null);
    const [details, setDetails] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    let { professionId } = useParams();
    const [isSaved, setIsSaved] = useState();
    const [userId, setUserId] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const { get35aProfessionsDescription } = useMetierService();
    const { saveProfession, deleteProfession } = useItemsService();
    const { isAuthenticated } = useAuth();

    const navigate = useNavigate();
    const [showRetourButton, setShowRetourButton] = useState(false);
    const { saveUserClickData } = useUserService();


    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.history.length > 2) {
            setShowRetourButton(true);
        }
    }, []);

    const handleRetourClick = () => {
        navigate(-1);
    };

    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible);
    };

    const fetchData = async () => {
        try {
            const response = await get35aProfessionsDescription(professionId, userId);
            if (response.status === "success") {
                const data = response.data;
                setDetails({
                    profession: data.profession,
                    educationExperience: data.education_experience,
                    professionId: data.profession_id,
                    careerProgression: data.career_progression,
                    workEnvironment: data.work_environment,
                    description: data.description,
                    dailyTasks: data.daily_tasks,
                    futureProspects: data.future_prospects,
                    image1: data.image1,
                    image2: data.image2,
                });
                setProfession({
                    professionId: data.profession_id,
                    profession: data.profession,
                    internalLink: data.profession_internal_link,
                    studyLevel: data.study_level,
                    businessSector: data.business_sect,
                    salary_1: data.salary_1,
                    salary_2: data.salary_2,
                    salary_3: data.salary_3,
                    salary_4: data.salary_4,
                    skills: data.skills,
                    employability: data.employability,
                    employabilityComment: data.employability_comment,
                    pictact: data.pictact,
                    requiredStudyLevel: data.required_stud_level,
                    saved: data.saved,
                });
                setIsSaved(data.saved);
            } else {
                setError(response.message || "An error occurred while fetching data.");
            }
        } catch (err) {
            setError(err.message || "An error occurred while fetching data.");
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     const user = localStorage.getItem('user_id');
    //     if (user) {
    //         const userId = CryptoService.decryptData(user);
    //         setUserId(userId);
    //     }
    // }, []);
    useEffect(() => {
        if (isAuthenticated) {
            const user = localStorage.getItem('user_id');
            if (user) {
                const decryptedId = CryptoService.decryptData(user);
                setUserId(decryptedId);
                fetchData();
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
            fetchData();
            //console.log("user",userId)
    }, [userId,isSaved, professionId]);

    if (loading) {
        return (
            <div className="flex justify-center mt-5">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const handleHeartClick = async () => {
        if (isSaved) {
            try {
                await handleDeleteProfession(professionId);
                setIsSaved(false);
            } catch (error) {
                console.error('Error deleting profession:', error);
            }
        } else {
            try {
                await handleSaveProfession(professionId);
                setIsSaved(true);
            } catch (error) {
                console.error('Error saving profession:', error);
            }
        }
    };


    const handleSaveProfession = async (professionId) => {
        if (!userId) {
            showModal();
        } else {
            try {
                await saveProfession(userId, professionId);
                notification.success({
                    message: 'Enregistré',
                    placement: 'topRight',
                });
                saveUserClickData({
                    idMetier: professionId,
                    userId,
                    enregistrer: true,
                });
            } catch (error) {
                console.error('Error saving profession:', error);
            }
        }
    };

    const handleDeleteProfession = async (professionId) => {
        try {
            await deleteProfession(userId, professionId);
            saveUserClickData({
                idMetier: professionId,
                userId,
                enregistrer: false,
            });
        } catch (error) {
            console.error('Error deleting profession:', error);
        }
    };

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);
    const showDatailModal = () => setPopupOpen(true);
    const hideDetailModal = () => setPopupOpen(false);

    return (
        <LayoutNo>
            <Helmet>
                <title>Linkyways</title>
            </Helmet>
            <div className="p-1 md:mt-0 mt-2">
            <div className="relative mt-1">
                <div
                    className="mt-6 md:mt-2 w-full h-48 rounded-xl bg-cover bg-center"
                    style={{ backgroundImage: `url(${details.image2.replace(/ /, '%20')})` }}
                ></div>
                {showRetourButton && (
                    <Button
                        icon={<LeftOutlined  className={"text-blue-500  text-lg"}/>}
                        onClick={handleRetourClick}
                        className="absolute p-1 top-2 left-2  shadow-md bg-white border-0  backdrop-blur-lg"
                        shape="circle"
                    />
                )}
                <div className="flex flex-col md:flex-row gap-2 mt-2">

                    {/* Save Button */}

                    {/* First Card - 80% of the screen */}
                    <div className="flex-grow md:w-9/12 bg-white shadow-lg rounded-lg p-2">
                        <div className="flex justify-between mb-2">
                            <button className=" md:hidden justify-start p-2 bg-white  rounded-md flex items-center" onClick={handleHeartClick}>

                                    <img
                                        // src={isSaved ? FilledHeartIcon : HeartIcon}
                                        src={!isAuthenticated || !isSaved ? HeartIcon : FilledHeartIcon}

                                        alt={isSaved ? "remove from favorites" : "add to favorites"}
                                        className="w-6 h-6 -mt-2 mr-4"
                                    />
                            </button>
                            <button
                                className="md:hidden flex gap-2 justify-end px-4 py-2 bg-[#1681FF] text-white rounded-xl"
                                onClick={showDatailModal}
                            >
                                Détails <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                                    <path d="M10.4727 12.6519L13.1243 10.0003L10.4727 7.34863" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.875 10H13.125" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </button>
                        </div>

                        <div className="flex justify-between mb-1">
                            <p className="justify-start font-poppins font-semibold text-[#141943] text-2xl">
                                {profession.profession}
                            </p>
                            <button className="invisible md:visible flex items-center justify-end gap-2  md:flex"
                             onClick={handleHeartClick}>
                        <img
                            src={!isAuthenticated || !isSaved ? HeartIcon : FilledHeartIcon}
                            alt={isSaved ? "remove from favorites" : "add to favorites"}
                           className="w-6 h-6 -mt-2 mr-4"
                        />
                    </button>
                        </div>

                        <div className="mt-2 mb-1 gap-2">
                            <p className="font-poppins font-bold text-[#141943] text-[18px]">
                                Description
                            </p>
                            <p className="font-inter text-[#141943] text-base">{details.description}</p>
                        </div>
                        <div className="mt-4 mb-1 gap-2">
                            <p className="font-poppins font-bold text-[#141943] text-[18px]">
                                Environnement de Travail
                            </p>
                            <p className="font-inter text-[#141943] text-base">{details.workEnvironment}</p>
                        </div>
                        <div className="mt-4 mb-1 gap-2">
                            <p className="font-poppins font-bold text-[#141943] text-[18px]">
                                Éducation et Expérience
                            </p>
                            <p className="font-inter text-[#141943] text-base">{details.educationExperience}</p>
                        </div>
                        <div className="mt-4 mb-1 gap-2">
                            <p className="font-poppins font-bold text-[#141943] text-[18px]">
                                Tâches Quotidiennes
                            </p>
                            {details.dailyTasks.split(";").map((task, index) => (
                                <div className="flex items-start " key={index}>
                                    <span className="font-inter text-[#141943] text-lg -mt-2">•</span>
                                    <p className="ml-2 font-inter text-[#141943] text-base -mt-2 ">{task}</p>
                                </div>
                            ))}
                        </div>

                        <div className="mt-3 gap-2">
                            <p className="font-poppins font-bold text-[#141943] text-[18px]">
                                Perspectives d'Évolution
                            </p>
                            <p className="font-inter font-normal text-[#141943] text-base">
                                {details.careerProgression}
                            </p>
                        </div>
                    </div>

                    {/* Second Card - 20% of the screen */}
                    <div className="md:w-3/12 bg-white shadow-lg rounded-lg p-2 hidden md:block">
                        <p className="font-poppins font-bold text-sm">Salaires</p>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-xs font-inter font-medium">Débutant :</p>
                            <p className="text-xs font-medium text-gray-600">{profession.salary_1.toLocaleString('fr-FR')} € - {profession.salary_2.toLocaleString('fr-FR')} €</p>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-xs font-medium">Expérimenté :</p>
                            {/*<p className="text-xs font-medium text-gray-600">{profession.salary_3} € - {profession.salary_4} €</p>*/}
                            <p className="text-xs font-medium text-gray-600"> {profession.salary_3.toLocaleString('fr-FR')} € - {profession.salary_4.toLocaleString('fr-FR')} €</p>
                        </div>

                        <hr className="my-3" />
                        <div>
                            <p className="font-poppins font-bold text-sm">Niveau requis</p>
                            <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full inline-flex items-center ">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                    <path d="M0.5 6L8 2L15.5 6L8 10L0.5 6Z" stroke="#434769" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M11.75 15V8L8 6" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.75 6.93359V10.3411C13.7502 10.449 13.7153 10.554 13.6507 10.6403C13.2296 11.2006 11.4533 13.2503 8 13.2503C4.54669 13.2503 2.77039 11.2006 2.34935 10.6403C2.28466 10.554 2.24979 10.449 2.25 10.3411V6.93359" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                {profession.requiredStudyLevel}

                            </span>
                            {/*<span className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded-full">{profession.requiredStudyLevel}</span>*/}
                        </div>
                        <hr className="my-3" />
                        <div>
                            <p className="font-poppins font-bold text-sm">Métier d'avenir</p>
                            <GradientBarWithArrow value={details.futureProspects} />
                        </div>
                        <hr className="my-3" />
                        <div>
                            <div className="flex items-center">
                                <h1 className="font-poppins font-bold text-sm">Employabilité</h1>
                                <HtmlTooltip
                                    title={
                                        <p className="text-sm leading-tight text-black">
                                            {profession.employabilityComment}
                                        </p>
                                    }
                                    placement="right"
                                >
                                    {/*<Button >*/}
                                    <img src={tooltipSvg} alt="Info"
                                        className="mb-2 object-cover h-[20px] ml-2 cursor-pointer" />
                                    {/*</Button>*/}
                                </HtmlTooltip>

                            </div>

                            <GradientBarWithArrow value={profession.employability} />
                        </div>
                        <hr className="my-3" />
                        <div className=" justify-between">
                            <p className="font-poppins font-bold text-sm">Formations</p>
                            {/*<p className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible</p>*/}
                            <Link to={'/'} className="w-full">
                                <button
                                    className="w-full bg-blue-500 text-white font-medium text-sm py-2 rounded-md"
                                >
                                    Rechercher une formation
                                </button>
                            </Link>
                        </div>
                        <hr className="my-3" />
                    </div>
                </div>

                {/* Popup for smaller screens */}
                <Modal
                    title={
                        <>
                            <div className="text-center flex flex-col items-center">

                            </div>
                        </>

                    }
                    visible={isPopupOpen}
                    onCancel={hideDetailModal}
                    footer={null}
                    centered
                    width={450}
                    // maskStyle={{ backgroundColor: 'rgb(255,255,255)' }}
                >
                        <div >
                            <p className="font-poppins font-bold text-sm p-2">Salaires</p>
                            <div className="flex justify-between items-center  px-2">
                                <p className="text-xs font-medium">Débutant :</p>
                                <p className="text-xs font-medium text-gray-600">{profession.salary_1.toLocaleString('fr-FR')} € - {profession.salary_2.toLocaleString('fr-FR')} €</p>
                            </div>
                            <div className="flex justify-between items-center  px-2">
                                <p className="text-xs font-medium">Expérimenté :</p>
                                <p className="text-xs font-medium text-gray-600"> {profession.salary_3.toLocaleString('fr-FR')} € - {profession.salary_4.toLocaleString('fr-FR')} €</p>
                            </div>

                            <hr className="my-1 px-2" />
                            <div className="p-2">
                                <p className="font-poppins font-bold text-sm">Niveau requis</p>
                                <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full inline-flex items-center ">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                                    <path d="M0.5 6L8 2L15.5 6L8 10L0.5 6Z" stroke="#434769" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M11.75 15V8L8 6" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.75 6.93359V10.3411C13.7502 10.449 13.7153 10.554 13.6507 10.6403C13.2296 11.2006 11.4533 13.2503 8 13.2503C4.54669 13.2503 2.77039 11.2006 2.34935 10.6403C2.28466 10.554 2.24979 10.449 2.25 10.3411V6.93359" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                    {profession.requiredStudyLevel}

                            </span>
                            </div>
                            <hr className="my-1 px-2" />
                            <div className="p-2">
                                <p className="font-poppins font-bold text-sm">Métier d'avenir</p>
                                <GradientBarWithArrow value={details.futureProspects} />
                            </div>
                            <hr className="my-1 px-2" />
                            <div className="p-2">
                                <div className="flex">
                                    <p className="font-poppins font-bold text-sm">Employabilité</p>
                                    <div className="relative">
                                        <img
                                            src={tooltipSvg}
                                            alt="Info"
                                            className=" -mt-1 object-cover ml-2 cursor-pointer"
                                            onClick={toggleTooltip}
                                        />
                                        {tooltipVisible && (
                                            <div className="absolute w-40 bg-white p-2 text-black text-sm leading-tight rounded-md shadow-lg z-30">
                                                {profession.employabilityComment}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <GradientBarWithArrow value={profession.employability} />
                            </div>
                            <hr className="my-3 p-1" />
                            <div className="flex justify-between p-2">
                                <div className="justify-start">
                                    <p className="font-poppins font-bold text-sm justify-start mt-2">Formations</p>

                                </div>
                                <div className="justify-end">
                                    <Link to={'/'} className="w-full justify-end">
                                        <button data-tracking="metiersdetailpage_chercher_formation"
                                            className=" w-full  bg-blue-500 text-white font-medium text-sm py-2 px-2 rounded-md"
                                        >
                                            Rechercher une formation
                                        </button>
                                    </Link>
                                </div>
                                {/*<p className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible</p>*/}
                            </div>
                        </div>
                </Modal>
                {/*{isPopupOpen && (*/}
                {/*    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">*/}
                {/*        <div className="bg-white  shadow-lg  w-full max-w-lg">*/}
                {/*            <div className="flex font-poppins font-bold justify-between border-2 p-2 shadow-md">*/}
                {/*                <p>*/}
                {/*                </p>*/}
                {/*                <button*/}
                {/*                    className=" text-black rounded-full justify-end"*/}
                {/*                    onClick={() => setPopupOpen(false)}*/}
                {/*                >*/}
                {/*                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                        <path d="M8.22168 8.22168L23.778 23.778" stroke="#141943" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />*/}
                {/*                        <path d="M8.22168 23.7783L23.778 8.22197" stroke="#141943" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />*/}
                {/*                    </svg>*/}

                {/*                </button>*/}
                {/*            </div>*/}
                {/*            <p className="font-poppins font-bold text-sm p-2">Salaires</p>*/}
                {/*            <div className="flex justify-between items-center  px-2">*/}
                {/*                <p className="text-xs font-medium">Débutant :</p>*/}
                {/*                <p className="text-xs font-medium text-gray-600">{profession.salary_1}€ - {profession.salary_2}€</p>*/}
                {/*            </div>*/}
                {/*            <div className="flex justify-between items-center  px-2">*/}
                {/*                <p className="text-xs font-medium">Expérimenté :</p>*/}
                {/*                <p className="text-xs font-medium text-gray-600">{profession.salary_3}€ - {profession.salary_4}€</p>*/}
                {/*            </div>*/}

                {/*            <hr className="my-1 px-2" />*/}
                {/*            <div className="p-2">*/}
                {/*                <p className="font-poppins font-bold text-sm">Niveau requis</p>*/}
                {/*                <span className="p-1 gap-1 text-[12px] font-inter bg-[#D0DBE773] text-[#141943] rounded-full inline-flex items-center ">*/}
                {/*                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="">*/}
                {/*                    <path d="M0.5 6L8 2L15.5 6L8 10L0.5 6Z" stroke="#434769" strokeLinecap="round"*/}
                {/*                          strokeLinejoin="round"/>*/}
                {/*                    <path d="M11.75 15V8L8 6" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                    <path d="M13.75 6.93359V10.3411C13.7502 10.449 13.7153 10.554 13.6507 10.6403C13.2296 11.2006 11.4533 13.2503 8 13.2503C4.54669 13.2503 2.77039 11.2006 2.34935 10.6403C2.28466 10.554 2.24979 10.449 2.25 10.3411V6.93359" stroke="#434769" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                </svg>*/}
                {/*                    {profession.requiredStudyLevel}*/}

                {/*            </span>*/}
                {/*            </div>*/}
                {/*            <hr className="my-1 px-2" />*/}
                {/*            <div className="p-2">*/}
                {/*                <p className="font-poppins font-bold text-sm">Métier d'avenir</p>*/}
                {/*                <GradientBarWithArrow value={details.futureProspects} />*/}
                {/*            </div>*/}
                {/*            <hr className="my-1 px-2" />*/}
                {/*            <div className="p-2">*/}
                {/*                <div className="flex">*/}
                {/*                <p className="font-poppins font-bold text-sm">Employabilité</p>*/}
                {/*                <div className="relative">*/}
                {/*                    <img*/}
                {/*                        src={tooltipSvg}*/}
                {/*                        alt="Info"*/}
                {/*                        className=" -mt-1 object-cover ml-2 cursor-pointer"*/}
                {/*                        onClick={toggleTooltip}*/}
                {/*                    />*/}
                {/*                    {tooltipVisible && (*/}
                {/*                        <div className="absolute w-40 bg-white p-2 text-black text-sm leading-tight rounded-md shadow-lg z-30">*/}
                {/*                            {profession.employabilityComment}*/}
                {/*                        </div>*/}
                {/*                    )}*/}
                {/*                </div>*/}
                {/*                </div>*/}
                {/*                <GradientBarWithArrow value={profession.employability} />*/}
                {/*            </div>*/}
                {/*            <hr className="my-1 p-1" />*/}
                {/*            <div className="flex justify-between p-2">*/}
                {/*                <div className="justify-start">*/}
                {/*                    <p className="font-poppins font-bold text-sm justify-start mt-2">Formations</p>*/}

                {/*                </div>*/}
                {/*                <div className="justify-end">*/}
                {/*                    <Link to={'/formations'} className="w-full justify-end">*/}
                {/*                        <button*/}
                {/*                            className=" w-full  bg-blue-500 text-white font-medium text-sm py-2 px-2 rounded-md"*/}
                {/*                        >*/}
                {/*                            Rechercher une formation*/}
                {/*                        </button>*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*                /!*<p className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible</p>*!/*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            <LoginModal visible={isModalVisible} onHide={hideModal} />
            </div>
        </LayoutNo>
    );
}
