import {useEffect, useState, useRef} from "react";
import LayoutNo from "../utils/LayoutNo";
import {MinusOutlined, PlusOutlined,ArrowRightOutlined} from "@ant-design/icons";
import {useFormationService} from "../services/formation.service";
import AproposSkeleton from "../utils/skeletons/AproposSkeleton";
import {Helmet} from "react-helmet";
import React from "react";
import {Link} from "react-router-dom"
import {Alert, Button, Select} from "antd";

import LoginModal from "../components/modal/auth/LoginModal";
import ContactApprovalModal from "../components/contactApprovalModal/ContactApprovalModal";
import {CryptoService} from "../services/crypte.service";
import {useUserService} from "../services/userforms.service";
import {useAuth} from "../services/auth.provider";
import ParcoursSupkeleton from "../utils/skeletons/ParcourssupSkeleton";


export default function ParcoursSup() {
    const [openIndex, setOpenIndex] = useState(null);
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSection, setSelectedSection] = useState("Tout savoir sur Parcoursup");
    const { Option } = Select;

    const [isModalApproved, setIsModalApproved] = useState(false);
    const [userId, setUserId] = useState(null);
    const userEmail = CryptoService.decryptData(localStorage.getItem('user_email'));
    const {isAuthenticated} = useAuth();
    const storedVisibility = localStorage.getItem('isContactButtonVisible');
    const {checkAppointment} = useUserService();

    const [isContactButtonVisible, setIsContactButtonVisible] = useState(
        storedVisibility ? JSON.parse(storedVisibility) : false
    );
    useEffect(() => {
        const user = localStorage.getItem('user_id');
        if (isAuthenticated && user) {
            const decryptedId = CryptoService.decryptData(user);
            setUserId(decryptedId);
            checkAppointment(decryptedId)
                .then(canMakeAppointment => {
                    setIsContactButtonVisible(canMakeAppointment);
                    localStorage.setItem('isContactButtonVisible', JSON.stringify(canMakeAppointment));
                })
                .catch(error => {
                    console.error("Error checking appointment:", error);
                    setIsContactButtonVisible(false);
                });
        } else {
            setIsContactButtonVisible(false);
            localStorage.removeItem('isContactButtonVisible');
        }
    }, [isAuthenticated, userEmail]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const onContactButtonHide = () => {
        setIsContactButtonVisible(false);
    };

    const {getFaqs} = useFormationService();

    const accordionRefs = useRef([]);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await getFaqs();
                setFaqs(response.data.data);
                // console.log(response.data.data)
                setLoading(false);
            } catch (error) {
                setError("Error fetching FAQs");
                setLoading(false);
            }
        };
        fetchFaqs();
    }, []);



    const toggleAccordion = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
            setTimeout(() => {
                const accordionElement = accordionRefs.current[index];
                if (accordionElement) {
                    const marginOffset = 50;
                    const elementTop = accordionElement.getBoundingClientRect().top + window.pageYOffset;
                    const adjustedPosition = elementTop - marginOffset;


                    window.scrollTo({
                        top: adjustedPosition,
                        behavior: "smooth",
                    });
                }
            }, 100);
        }
    };



    const renderContent = (responses, types) => {
        return responses.map((response, index) => {
            if (!response) return null;

            const type = types[index];
            const commonClass = "mb-2   leading-relaxed  ";

            switch (type) {
                case "sous-titre":
                    return (
                        <p key={index} className={`${commonClass} font-bold text-[#141943]  pt-3 pb-1 `}>
                            {response}
                        </p>
                    );
                case "txt":
                    return (
                        <p key={index} className={` ${commonClass} text-justify`}>
                            {response}
                        </p>
                    );
                case "list":
                    return (
                        <ul key={index} className={`list-disc ml-6  ${commonClass} text-justify`}>
                            {response.split(";").map((item, i) => (
                                <li key={i}>{item.trim()}</li>
                            ))}
                        </ul>
                    );
                case "astuce":
                    return (
                        <div
                            key={index}
                            className=" bg-[#E3F2FF80] p-3   rounded-lg  text-[#141943] my-4 flex items-center gap-3 text-sm text-justify leading-6"
                        >
                            <svg width="70" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7218 4.6891L2.53187 10.3512C2.53187 10.3512 0.600278 10.8129 0.980157 13.0773C1.36004 15.3418 3.37082 30.1899 3.45517 30.9053C3.53951 31.6206 3.79062 32.2052 4.29219 31.9535C4.79376 31.7017 25.8088 19.3293 26.0606 19.205C26.3123 19.0807 26.6059 18.8695 26.6478 17.9044C26.6896 16.9392 27.0251 1.5889 27.0251 1.04355C27.0251 0.498194 26.9433 -0.302128 25.8519 0.117027C24.7606 0.536182 21.3224 1.58503 20.8189 1.71123C20.5674 1.7743 20.3357 1.89948 20.1452 2.0753C19.9546 2.25113 19.8112 2.47197 19.7282 2.71759C19.5608 3.21916 19.2253 4.52105 18.7218 4.6891Z" fill="#31A0FC"/>
                                <path d="M18.7486 4.79367L2.67973 10.4236C2.67973 10.4236 0.775181 10.8808 1.14798 13.115C1.52077 15.3492 3.50709 30.0074 3.59079 30.7117C3.6745 31.4161 3.92174 31.995 4.4188 31.7471C4.91587 31.4992 25.656 19.2838 25.9065 19.1595C26.1569 19.0353 26.5085 18.8318 26.5503 17.8795C26.5922 16.9273 26.9437 1.77717 26.9437 1.2389C26.9437 0.700632 26.9907 -0.113211 25.7004 0.321396C24.6058 0.689686 21.2288 1.77009 20.7324 1.89435C20.2359 2.01862 19.8342 2.48156 19.7086 3.03206C19.5908 3.54071 19.245 4.62755 18.7486 4.79367Z" fill="url(#paint0_linear_4776_29170)"/>
                                <path d="M1.98633 11.9443L3.95784 31.3639L25.6419 18.5716L26.1035 3.01074L1.98633 11.9443Z" fill="url(#paint1_linear_4776_29170)"/>
                                <path d="M2.36426 12.0713L4.33577 31.4902L26.0191 18.6979L26.4808 3.1377L2.36426 12.0713Z" fill="#E5F5FF"/>
                                <path d="M26.0256 18.5246L26.4698 3.53223L2.74219 12.3222L4.54565 31.1965L26.0256 18.5246Z" fill="#CDD0E1"/>
                                <path d="M3.11914 12.4482L4.9226 31.3225L26.6073 18.5302L27.2363 3.51465L3.11914 12.4482Z" fill="#E5F5FF"/>
                                <path d="M26.6222 18.1689L27.2281 3.71484L3.53906 12.4901L5.21633 31.0288L26.6222 18.1689Z" fill="#CDD0E1"/>
                                <path d="M3.79102 12.6162L5.46828 31.1543L26.9012 18.2784L27.9076 3.68262L3.79102 12.6162Z" fill="#E5F5FF"/>
                                <path d="M27.892 3.90723L4.04199 12.7417L5.67805 30.6932L26.6493 18.1521L27.4046 10.9801L27.892 3.90723Z" fill="#CDD0E1"/>
                                <path d="M4.29395 12.8672L5.93 30.8187L26.9013 18.2782L28.4111 3.93359L4.29395 12.8672Z" fill="#E5F5FF"/>
                                <path d="M5.85569 30.7645C10.5733 28.0191 26.3305 18.7552 26.6525 18.5955C26.7307 18.5583 26.806 18.5152 26.8778 18.4667L28.3632 4.3584C24.3249 5.9481 7.65787 12.5058 6.55235 12.9308C5.58012 13.3049 4.72829 14.055 4.41602 14.9602L5.85569 30.7645Z" fill="#93BAE9"/>
                                <path d="M4.1567 31.2273C4.1567 31.4256 4.1567 31.8242 4.01892 31.7611C3.85666 31.6858 4.16057 32.0154 4.32475 31.9407C4.67115 31.783 26.0616 19.2006 26.4389 19.012C26.8162 18.8233 27.0055 18.6971 27.2881 17.4705C27.5708 16.244 29.8359 7.05799 30.0883 5.70523C30.3407 4.35247 30.0239 4.03827 28.9873 4.44712C27.9507 4.85597 7.56274 12.8772 6.3394 13.3505C5.11606 13.8237 4.07429 14.8919 4.07429 16.1191C4.07429 17.3463 4.1567 31.2273 4.1567 31.2273Z" fill="#31A0FC"/>
                                <path d="M6.33933 13.3508C5.11213 13.8221 4.07422 14.8922 4.07422 16.1194C4.07422 16.8135 4.10126 21.5542 4.12444 25.5436C5.06019 25.2831 5.99873 24.9826 6.94005 24.6422C17.6224 20.7842 24.9927 12.9973 25.5348 5.80664C19.4098 8.21791 7.27551 12.9896 6.33933 13.3508Z" fill="url(#paint2_radial_4776_29170)"/>
                                <path d="M4.31443 26.1316L4.355 31.1827C4.355 31.1827 4.27967 31.9804 4.1084 32.0042C4.21114 32.0004 4.31134 31.9712 4.40007 31.9192L26.6983 18.8578C26.6983 18.8578 27.0022 18.7085 27.2237 17.7575C27.5276 16.4517 27.8489 15.1582 27.8489 15.1582L4.31443 26.1316Z" fill="url(#paint3_linear_4776_29170)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_4776_29170" x1="14.4032" y1="9.95939" x2="17.3605" y2="34.1809" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#2CB8F3"/>
                                        <stop offset="1" stop-color="#517EA0"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_4776_29170" x1="14.1354" y1="8.44688" x2="16.0232" y2="28.6423" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#2FB4F6"/>
                                        <stop offset="1" stop-color="#517EA0"/>
                                    </linearGradient>
                                    <radialGradient id="paint2_radial_4776_29170" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.9599 19.1179) scale(18.8639)">
                                        <stop stop-color="#94E9FE"/>
                                        <stop offset="1" stop-color="#31A0FC"/>
                                    </radialGradient>
                                    <linearGradient id="paint3_linear_4776_29170" x1="16.5324" y1="24.6455" x2="15.479" y2="22.6212" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#2293F0"/>
                                        <stop offset="1" stop-color="#31A0FC"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                            {response}
                        </div>
                    );
                case "conseil":
                    return (
                        <div
                            key={index}
                            className=" p-3  bg-[#FFF0A480] rounded-lg  text-[#141943] my-4 flex items-center gap-3 text-sm text-justify leading-6"
                        >
                            <svg width="70" height="40" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.0003 2.25849C12.914 2.25849 12.8313 2.22421 12.7703 2.1632C12.7093 2.10218 12.675 2.01942 12.675 1.93313V0.325411C12.6736 0.281782 12.681 0.238317 12.6967 0.197597C12.7124 0.156878 12.7362 0.119736 12.7665 0.0883789C12.7969 0.0570213 12.8333 0.0320879 12.8735 0.0150601C12.9136 -0.00196762 12.9569 -0.0107422 13.0005 -0.0107422C13.0442 -0.0107422 13.0874 -0.00196762 13.1276 0.0150601C13.1678 0.0320879 13.2041 0.0570213 13.2345 0.0883789C13.2648 0.119736 13.2886 0.156878 13.3043 0.197597C13.3201 0.238317 13.3274 0.281782 13.326 0.325411V1.93313C13.326 1.97588 13.3176 2.01822 13.3012 2.05772C13.2849 2.09721 13.2609 2.1331 13.2306 2.16331C13.2004 2.19353 13.1645 2.21749 13.125 2.23382C13.0854 2.25015 13.0431 2.25854 13.0003 2.25849Z" fill="#FFCB00"/>
                                <path d="M20.5034 5.36659C20.439 5.36658 20.376 5.34748 20.3225 5.31171C20.269 5.27594 20.2272 5.22511 20.2026 5.16563C20.1779 5.10615 20.1714 5.0407 20.184 4.97754C20.1965 4.91438 20.2274 4.85635 20.2729 4.81078L21.41 3.67401C21.4717 3.61617 21.5535 3.5846 21.6381 3.58598C21.7227 3.58736 21.8034 3.62159 21.8632 3.68142C21.923 3.74125 21.9571 3.82198 21.9584 3.90655C21.9598 3.99112 21.9281 4.07289 21.8702 4.13456L20.7335 5.27133C20.7033 5.30154 20.6674 5.3255 20.6279 5.34185C20.5884 5.35819 20.5461 5.3666 20.5034 5.36659Z" fill="#FFCB00"/>
                                <path d="M4.35948 21.5101C4.29512 21.51 4.23223 21.4908 4.17874 21.455C4.12525 21.4192 4.08357 21.3684 4.05895 21.3089C4.03433 21.2495 4.02788 21.1841 4.04042 21.1209C4.05295 21.0578 4.08391 20.9998 4.12937 20.9543L5.26615 19.8171C5.29619 19.7861 5.33213 19.7613 5.37187 19.7442C5.4116 19.7271 5.45433 19.7182 5.49757 19.7178C5.54081 19.7174 5.58369 19.7257 5.62371 19.742C5.66373 19.7584 5.70009 19.7826 5.73067 19.8132C5.76125 19.8437 5.78543 19.8801 5.8018 19.9201C5.81818 19.9601 5.82642 20.003 5.82605 20.0463C5.82568 20.0895 5.81671 20.1322 5.79965 20.172C5.78259 20.2117 5.75779 20.2476 5.72669 20.2777L4.58992 21.4145C4.55971 21.4448 4.5238 21.4689 4.48425 21.4853C4.4447 21.5017 4.4023 21.5101 4.35948 21.5101Z" fill="#FFCB00"/>
                                <path d="M25.2193 12.87H23.6115C23.5679 12.8714 23.5244 12.864 23.4837 12.8483C23.443 12.8325 23.4059 12.8088 23.3745 12.7784C23.3432 12.7481 23.3182 12.7117 23.3012 12.6715C23.2842 12.6313 23.2754 12.5881 23.2754 12.5445C23.2754 12.5008 23.2842 12.4576 23.3012 12.4174C23.3182 12.3772 23.3432 12.3408 23.3745 12.3105C23.4059 12.2801 23.443 12.2564 23.4837 12.2406C23.5244 12.2249 23.5679 12.2175 23.6115 12.2189H25.2193C25.3038 12.2216 25.3839 12.2571 25.4428 12.3179C25.5016 12.3786 25.5345 12.4599 25.5345 12.5445C25.5345 12.629 25.5016 12.7103 25.4428 12.771C25.3839 12.8318 25.3038 12.8673 25.2193 12.87Z" fill="#FFCB00"/>
                                <path d="M2.3882 12.87H0.780489C0.73686 12.8714 0.693395 12.864 0.652676 12.8483C0.611956 12.8325 0.574815 12.8088 0.543457 12.7784C0.512099 12.7481 0.487166 12.7117 0.470138 12.6715C0.453111 12.6313 0.444336 12.5881 0.444336 12.5445C0.444336 12.5008 0.453111 12.4576 0.470138 12.4174C0.487166 12.3772 0.512099 12.3408 0.543457 12.3105C0.574815 12.2801 0.611956 12.2564 0.652676 12.2406C0.693395 12.2249 0.73686 12.2175 0.780489 12.2189H2.3882C2.47273 12.2216 2.55289 12.2571 2.61173 12.3179C2.67056 12.3786 2.70346 12.4599 2.70346 12.5445C2.70346 12.629 2.67056 12.7103 2.61173 12.771C2.55289 12.8318 2.47273 12.8673 2.3882 12.87Z" fill="#FFCB00"/>
                                <path d="M21.6394 21.5101C21.5967 21.5101 21.5543 21.5017 21.5148 21.4853C21.4753 21.4689 21.4395 21.4448 21.4093 21.4145L20.2722 20.2777C20.2411 20.2476 20.2163 20.2117 20.1993 20.172C20.1822 20.1322 20.1732 20.0895 20.1729 20.0463C20.1725 20.003 20.1807 19.9601 20.1971 19.9201C20.2135 19.8801 20.2377 19.8437 20.2682 19.8132C20.2988 19.7826 20.3352 19.7584 20.3752 19.742C20.4152 19.7257 20.4581 19.7174 20.5013 19.7178C20.5446 19.7182 20.5873 19.7271 20.627 19.7442C20.6668 19.7613 20.7027 19.7861 20.7328 19.8171L21.8695 20.9543C21.915 20.9998 21.946 21.0578 21.9585 21.1209C21.971 21.1841 21.9646 21.2495 21.94 21.3089C21.9153 21.3684 21.8737 21.4192 21.8202 21.455C21.7667 21.4908 21.7038 21.51 21.6394 21.5101Z" fill="#FFCB00"/>
                                <path d="M5.49678 5.36707C5.454 5.36711 5.41163 5.35871 5.37209 5.34237C5.33255 5.32602 5.29661 5.30205 5.26634 5.27181L4.12957 4.13504C4.07022 4.07362 4.03737 3.99133 4.0381 3.90592C4.03883 3.82051 4.07308 3.7388 4.13348 3.6784C4.19388 3.61801 4.27559 3.58375 4.361 3.58302C4.44641 3.58229 4.52869 3.61514 4.59012 3.67449L5.72689 4.81126C5.77236 4.85681 5.80331 4.91481 5.81585 4.97794C5.82838 5.04107 5.82193 5.10649 5.79731 5.16596C5.77269 5.22542 5.73101 5.27626 5.67752 5.31205C5.62404 5.34785 5.56114 5.36699 5.49678 5.36707Z" fill="#FFCB00"/>
                                <path d="M17.0977 3.08204C17.0439 3.08228 16.9909 3.0692 16.9434 3.04395C16.8959 3.01871 16.8554 2.9821 16.8255 2.93738C16.7956 2.89267 16.7772 2.84124 16.772 2.7877C16.7668 2.73416 16.775 2.68017 16.7958 2.63055L17.4167 1.14762C17.4327 1.10743 17.4565 1.07083 17.4868 1.03996C17.5171 1.00908 17.5532 0.984553 17.5931 0.9678C17.633 0.951047 17.6758 0.942407 17.7191 0.942383C17.7623 0.942359 17.8052 0.950952 17.8451 0.967661C17.885 0.984369 17.9211 1.00886 17.9515 1.0397C17.9818 1.07054 18.0057 1.10711 18.0217 1.14729C18.0378 1.18746 18.0457 1.23042 18.0449 1.27367C18.0442 1.31692 18.0348 1.35959 18.0174 1.39919L17.3982 2.88213C17.3734 2.94137 17.3316 2.99196 17.2782 3.02753C17.2247 3.0631 17.1619 3.08207 17.0977 3.08204Z" fill="#FFCB00"/>
                                <path d="M22.8191 8.84699C22.7436 8.84728 22.6703 8.8213 22.6118 8.77352C22.5533 8.72573 22.5133 8.6591 22.4985 8.58504C22.4837 8.51097 22.4951 8.43407 22.5308 8.36749C22.5665 8.30092 22.6241 8.2488 22.694 8.22007L24.1816 7.61026C24.2617 7.57748 24.3515 7.57785 24.4313 7.61128C24.5111 7.64471 24.5743 7.70848 24.6071 7.78854C24.6399 7.86861 24.6395 7.95842 24.6061 8.03821C24.5727 8.11801 24.5089 8.18125 24.4288 8.21404L22.9422 8.82183C22.9033 8.83828 22.8614 8.84683 22.8191 8.84699Z" fill="#FFCB00"/>
                                <path d="M1.69283 17.5035C1.61723 17.5037 1.54389 17.4777 1.48541 17.4298C1.42692 17.3819 1.38695 17.3151 1.37234 17.2409C1.35774 17.1668 1.36942 17.0898 1.40538 17.0233C1.44133 16.9568 1.49933 16.9049 1.56939 16.8765L3.05703 16.2684C3.13709 16.2357 3.22688 16.2361 3.30665 16.2695C3.38641 16.303 3.44961 16.3668 3.48235 16.4469C3.51509 16.5269 3.51468 16.6167 3.48121 16.6965C3.44775 16.7762 3.38397 16.8394 3.3039 16.8722L1.81627 17.4793C1.77707 17.4953 1.73515 17.5035 1.69283 17.5035Z" fill="#FFCB00"/>
                                <path d="M24.2701 17.5896C24.227 17.5895 24.1844 17.5808 24.1447 17.5641L22.6614 16.9432C22.5832 16.9088 22.5217 16.8451 22.49 16.7658C22.4584 16.6865 22.4591 16.598 22.4921 16.5192C22.5251 16.4405 22.5876 16.3778 22.6664 16.3448C22.7451 16.3117 22.8336 16.3109 22.913 16.3424L24.3959 16.9633C24.4654 16.9923 24.5227 17.0445 24.558 17.111C24.5933 17.1775 24.6045 17.2542 24.5897 17.328C24.5749 17.4018 24.5349 17.4682 24.4767 17.5159C24.4184 17.5636 24.3454 17.5896 24.2701 17.5896Z" fill="#FFCB00"/>
                                <path d="M3.21142 8.77175C3.16822 8.77162 3.12547 8.76295 3.08563 8.74626L1.6027 8.12538C1.56251 8.10938 1.52591 8.08553 1.49503 8.05524C1.46416 8.02494 1.43963 7.98879 1.42288 7.94891C1.40613 7.90903 1.39748 7.86621 1.39746 7.82295C1.39744 7.7797 1.40603 7.73687 1.42274 7.69697C1.43945 7.65707 1.46394 7.62089 1.49478 7.59056C1.52562 7.56023 1.56219 7.53634 1.60236 7.5203C1.64254 7.50426 1.6855 7.49638 1.72875 7.49712C1.772 7.49786 1.81467 7.50721 1.85427 7.52462L3.33721 8.1455C3.40668 8.17452 3.46396 8.22671 3.4993 8.2932C3.53463 8.35969 3.54584 8.43637 3.53101 8.51019C3.51619 8.58401 3.47624 8.65041 3.41797 8.6981C3.3597 8.74579 3.28671 8.77181 3.21142 8.77175Z" fill="#FFCB00"/>
                                <path d="M8.97496 3.0526C8.91035 3.05282 8.84713 3.0338 8.79336 2.99795C8.7396 2.96211 8.69772 2.91107 8.67308 2.85134L8.06528 1.3617C8.03254 1.28163 8.03295 1.19184 8.06641 1.11208C8.09988 1.03231 8.16366 0.969109 8.24373 0.936371C8.32379 0.903633 8.41358 0.904042 8.49335 0.937507C8.57311 0.970973 8.63631 1.03475 8.66905 1.11482L9.27685 2.60245C9.29725 2.65201 9.30512 2.70583 9.29976 2.75915C9.29441 2.81247 9.27599 2.86365 9.24614 2.90816C9.21629 2.95267 9.17593 2.98913 9.12863 3.01432C9.08133 3.03952 9.02855 3.05266 8.97496 3.0526Z" fill="#FFCB00"/>
                                <path d="M15.8506 28.0352H10.3519C10.2029 28.0352 10.06 27.976 9.9546 27.8706C9.84923 27.7652 9.79004 27.6223 9.79004 27.4733C9.79 27.3995 9.80449 27.3264 9.83271 27.2582C9.86093 27.19 9.9023 27.128 9.95448 27.0758C10.0067 27.0236 10.0686 26.9822 10.1368 26.9539C10.205 26.9257 10.2781 26.9111 10.3519 26.9111H15.8506C15.9997 26.9111 16.1427 26.9704 16.2481 27.0758C16.3535 27.1812 16.4128 27.3242 16.4128 27.4733C16.4128 27.5471 16.3982 27.6202 16.37 27.6884C16.3417 27.7566 16.3003 27.8185 16.2481 27.8707C16.1959 27.9229 16.1339 27.9643 16.0657 27.9925C15.9975 28.0207 15.9244 28.0352 15.8506 28.0352Z" fill="#002A47"/>
                                <path d="M15.8506 29.8004H10.3519C10.2029 29.8004 10.06 29.7413 9.9546 29.6359C9.84923 29.5305 9.79004 29.3876 9.79004 29.2386C9.79004 29.0896 9.84923 28.9467 9.9546 28.8413C10.06 28.736 10.2029 28.6768 10.3519 28.6768H15.8506C15.9244 28.6767 15.9975 28.6912 16.0657 28.7194C16.1339 28.7476 16.1959 28.789 16.2481 28.8412C16.3003 28.8934 16.3417 28.9553 16.37 29.0235C16.3982 29.0917 16.4128 29.1648 16.4128 29.2386C16.4128 29.3124 16.3982 29.3855 16.37 29.4537C16.3417 29.5219 16.3003 29.5838 16.2481 29.636C16.1959 29.6882 16.1339 29.7296 16.0657 29.7578C15.9975 29.786 15.9244 29.8005 15.8506 29.8004Z" fill="#002A47"/>
                                <path d="M11.7678 30.4648C11.7358 30.4649 11.7042 30.472 11.6752 30.4856C11.6463 30.4992 11.6207 30.519 11.6002 30.5437C11.5798 30.5683 11.565 30.5971 11.5569 30.628C11.5488 30.659 11.5476 30.6913 11.5534 30.7228C11.6206 31.0802 11.8106 31.4029 12.0905 31.6352C12.3704 31.8674 12.7226 31.9945 13.0863 31.9945C13.45 31.9945 13.8023 31.8674 14.0822 31.6352C14.3621 31.4029 14.5521 31.0802 14.6192 30.7228C14.6251 30.6913 14.624 30.6589 14.6159 30.628C14.6078 30.597 14.5931 30.5682 14.5726 30.5435C14.5521 30.5189 14.5265 30.4991 14.4975 30.4855C14.4685 30.4719 14.4369 30.4648 14.4049 30.4648H11.7678Z" fill="#002A47"/>
                                <path d="M15.2693 26.2038H10.8527C10.4819 26.2026 10.1252 26.0618 9.85344 25.8095C9.58172 25.5573 9.4149 25.212 9.38617 24.8423C9.199 22.3527 8.18332 20.7752 7.10759 19.1065C5.93359 17.2851 4.72168 15.402 4.72168 12.3566C4.72168 8.24423 7.6634 4.7836 11.7161 4.12783C12.6736 3.97423 13.6504 3.98558 14.6041 4.16138C18.5431 4.89697 21.4006 8.34352 21.4006 12.3553C21.4006 15.4006 20.1877 17.2837 19.0147 19.1051C17.939 20.7752 16.9229 22.3527 16.7358 24.841C16.7074 25.2109 16.5407 25.5565 16.2689 25.809C15.9972 26.0616 15.6403 26.2025 15.2693 26.2038Z" fill="#FFCB00"/>
                                <path d="M9.02204 17.5794C8.9364 17.5795 8.85195 17.5593 8.77562 17.5205C8.69929 17.4817 8.63326 17.4253 8.58296 17.356C5.57013 13.2181 7.79101 9.33416 7.88694 9.17148C7.96086 9.04958 8.07978 8.96165 8.218 8.92671C8.35621 8.89177 8.50262 8.91261 8.62559 8.98474C8.74857 9.05687 8.83822 9.17448 8.87518 9.31217C8.91215 9.44986 8.89345 9.59656 8.82313 9.72058C8.74028 9.8638 6.87764 13.1711 9.46045 16.718C9.51945 16.7989 9.55491 16.8945 9.56292 16.9943C9.57093 17.0941 9.55116 17.1941 9.5058 17.2834C9.46045 17.3726 9.39128 17.4476 9.30596 17.4999C9.22063 17.5523 9.12248 17.58 9.02237 17.58L9.02204 17.5794Z" fill="white"/>
                            </svg>
                            {response}
                        </div>

                    );
                case "txt-ul":
                    return (
                        <p key={index} className={`${commonClass}  underline`}>
                            {response}
                        </p>
                    );
                case "txt-blue":
                    return (
                        <p key={index} className={`${commonClass}  font-bold text-blue-500`}>
                            {response}
                        </p>
                    );
                case "list_flèche":
                    return (
                        <ul key={index} className={`list-none    ${commonClass} text-justify`}>
                            {response.split(";").map((item, i) => (
                                <li key={i} className=" before:mr-2">
                                    {item.trim()}
                                </li>
                            ))}
                        </ul>
                    );
                case "txt-green":
                    return (
                        <p key={index} className={`${commonClass}  text-green-500`}>
                            {response}
                        </p>
                    );
                case "lien":
                    const [displayText, link] = response.split("|").map((part) => part.trim());

                    return (
                        <a
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${commonClass}`}
                        >
                            <p className="text-blue-500 underline">{displayText || "Click here"}</p>
                        </a>
                    );

                case "logo-instit":
                    return (
                        <div key={index} className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 py-10">
                            {response.split(";").map((imgSrc, i) => (
                                <img key={i} src={imgSrc.trim()} alt={`Logo ${i + 1}`}
                                     className="w-[150px] h-[100px] object-contain p-2  rounded-md mx-auto "

                                />
                            ))}
                        </div>

                    );

                case "list_num":
                    return (
                        <ol key={index} className={`list-decimal ml-8 ${commonClass} text-justify`}>
                            {response.split(";").map((item, i) => (
                                <li key={i}>{item.trim()}</li>
                            ))}
                        </ol>
                    );
                default:
                    return (
                        <p key={index} className={`${commonClass}`}>
                            {response}
                        </p>
                    );
            }
        });
    };


    const filteredFaqs = faqs.filter((faq) => faq.section === selectedSection);

    if (loading) {
        return (
            <ParcoursSupkeleton/>
        );
    }

    if (error) return <p>{error}</p>;

    return (
        <LayoutNo>
            <Helmet>
                <title>Faq</title>
            </Helmet>
            <div className="flex flex-col md:flex-row gap-4 p-3 md:mt-1 mt-6">
                {/* Left Section */}
                <div className="md:w-1/4">
                    {/* Dropdown for small screens */}
                    <div className="block md:hidden ">
                        <button
                            className={`block w-full flex justify-between  text-left py-2 border-b-2  font-poppins text-[16px] text-blue-600 font-bold `}
                        >
                            <span>Tout savoir sur Parcoursup</span>
                            <ArrowRightOutlined   className={`mt-1 hidden md:flex  text-sm border-1 rounded-full border-black p-1 text-blue-600 border-blue-600 font-bold `} />
                        </button>
                    </div>

                    {/* Regular section buttons for large screens */}
                    <div className="hidden md:block space-y-2">
                            <button
                                className={`block w-full flex justify-between  text-left py-2 border-b-2  font-poppins text-[16px] text-blue-600 font-bold `}
                            >
                                <span>Tout savoir sur Parcoursup</span>
                                <ArrowRightOutlined   className={`mt-1 text-sm border-1 rounded-full border-black p-1 text-blue-600 border-blue-600 font-bold `} />
                            </button>
                    </div>
                </div>

                {/* Right Section */}
                <div className="md:w-3/4 w-full">
                    {filteredFaqs.map((faq, index) => (
                        <div
                            key={index}
                            className="border-1 border-[#D0DBE7] bg-white rounded-md  mb-2 p-2 shadow-md"
                            ref={(el) => (accordionRefs.current[index] = el)}
                        >
                            <button
                                className="flex justify-between items-center w-full py-2  md:px-2 px-1 text-left font-bold text-[#141943] font-inter md:text-lg text-md"
                                onClick={() => toggleAccordion(index)}
                            >
                                <span className="flex items-center gap-x-4">
                                   {/*<span className="max-w-2">{faq.section_id}</span>*/}
                                    <span className="md:ml-[35px] ml-[18px] ">{faq.question}</span>
                               </span>

                                <span className="text-gray-900 font-bold text-2xl   p-1 flex items-center justify-center">
                                {openIndex === index ? (
                                    <MinusOutlined className="md:text-xl text-sm text-gray-500   border-2 border-gray-500 rounded-full p-1 " />
                                ) : (
                                    <PlusOutlined className="md:text-xl text-sm text-blue-500   border-2 border-blue-500 rounded-full p-1 "  />
                                )}
                            </span>
                            </button>
                            {openIndex === index && (
                                <div className="py-2 md:px-6 px-2  font-inter text-[#434769]">
                                    {renderContent(
                                        [
                                            faq.response_1,
                                            faq.response_2,
                                            faq.response_3,
                                            faq.response_4,
                                            faq.response_5,
                                            faq.response_6,
                                            faq.response_7,
                                            faq.response_8,
                                            faq.response_9,
                                            faq.response_10,
                                            faq.response_11,
                                            faq.response_12,
                                            faq.response_13,
                                            faq.response_14,
                                            faq.response_15,
                                            faq.response_16,
                                            faq.response_17,
                                            faq.response_18,
                                            faq.response_19,
                                            faq.response_20,
                                            faq.response_21,
                                            faq.response_22,
                                            faq.response_23,
                                        ],
                                        [
                                            faq.type_1,
                                            faq.type_2,
                                            faq.type_3,
                                            faq.type_4,
                                            faq.type_5,
                                            faq.type_6,
                                            faq.type_7,
                                            faq.type_8,
                                            faq.type_9,
                                            faq.type_10,
                                            faq.type_11,
                                            faq.type_12,
                                            faq.type_13,
                                            faq.type_14,
                                            faq.type_15,
                                            faq.type_16,
                                            faq.type_17,
                                            faq.type_18,
                                            faq.type_19,
                                            faq.type_20,
                                            faq.type_21,
                                            faq.type_22,
                                            faq.type_23,
                                        ]
                                    )}
                                    {faq.cta && (
                                        <div className="mt-4 flex justify-end">
                                            {faq.cta === "/contact" &&
                                            ((isContactButtonVisible && isAuthenticated) || !isAuthenticated) ? (
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        if (isAuthenticated) {
                                                            setIsModalApproved(true);
                                                        } else {
                                                            showModal();
                                                        }
                                                    }}
                                                    className="bg-green-500 font-semibold font-poppins text-md gap-3 px-5 py-3"
                                                >
                                                    Être conseillé(e)
                                                </Button>
                                            ) : faq.cta !== "/contact" ? (
                                                <Link to={faq.cta}>
                                                    <Button
                                                        type="primary"
                                                        className="font-semibold font-poppins text-md gap-3 px-5 py-3"
                                                    >
                                                        {faq.button}
                                                    </Button>
                                                </Link>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <ContactApprovalModal
                userId={userId}
                userEmail={userEmail}
                isVisible={isModalApproved}
                onClose={() => setIsModalApproved(false)}
                onContactButtonHide={onContactButtonHide}
            />
            <LoginModal visible={isModalVisible} onHide={hideModal} />
        </LayoutNo>

    );
}


