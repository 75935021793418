import React, { useState } from 'react';
import { Modal, Input, Button, Alert } from 'antd';
import { useAuthService } from '../../../services/auth.service';
import { OtpPasswordModal } from './OtpPasswordModal';

export function ResetPasswordModal({ visible, onHide }) {
    const [email, setEmail] = useState('');
    const [resetOtpVisible, setResetOtpVisible] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { forgotPassword } = useAuthService();

    const handleSubmit = async () => {
        setIsLoading(true);
        if (email) {
            try {
                const response = await forgotPassword(email);
                if (response?.data.status === 'success') {
                    setShowAlert(false);
                    onHide();
                    setResetOtpVisible(true);
                } else {
                    setShowAlert(true);
                }
            } catch (error) {
                setShowAlert(true);
                console.error("Password reset failed:", error);
            }
        } else {
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const resetForm = () => {
        setEmail('');
        setShowAlert(false);
    };

    return (
        <>
            <Modal
                title={<h2 className="text-xl font-semibold mb-2 text-center">Réinitialiser le mot de passe</h2>}
                visible={visible}
                onCancel={() => {
                    resetForm();
                    onHide();
                }}
                footer={null}
                centered
                style={{padding:'50px'}}
            >
                {showAlert && (
                    <Alert
                        message="Veuillez entrer une adresse e-mail valide."
                        type="warning"
                        showIcon
                        style={{ marginBottom: '16px' }}
                    />
                )}
                <p className="mb-4 text-sm text-center">Veuillez indiquer l'adresse e-mail associée à votre compte Linkyways. Nous vous enverrons un e-mail contenant des instructions pour changer votre mot de passe.</p>

                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="reset-email" style={{ display: 'block', marginBottom: '8px' }}>
                        E-mail
                    </label>
                    <Input
                        style={{ fontSize: '16px' }}
                        type="email"
                        id="reset-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="vous@example.com"
                    />
                </div>
                <Button
                    type="primary"
                    block
                    onClick={handleSubmit}
                    loading={isLoading}
                >
                    Continuer
                </Button>
            </Modal>
            <OtpPasswordModal email={email} visible={resetOtpVisible} onHide={() => setResetOtpVisible(false)} />
        </>
    );
}

