import React, {useEffect, useRef, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate, matchPath} from 'react-router-dom';
import Progress from '../components/progress/Progress';
import {useProgress} from '../context/ProgressContext';
import Homeicon from "../images/home";
import LampIcon from "../images/lamp";
import HeartIcon from "../images/heart";
import {useAuth} from '../services/auth.provider';
import {CryptoService} from '../services/crypte.service';
import Navbar from "../components/Navbar";
import "../style/hidescrollbar.css";
import {useUserService} from '../services/userforms.service';
import LandingNav from '../components/LandingNav';
import LoginModal from "../components/modal/auth/LoginModal";
import {LogoutOutlined, QuestionCircleOutlined, RobotFilled} from '@ant-design/icons';
import UserIcon from "../images/iconuser.svg";
import {Modal} from "antd";
import ContactAvatar from "../images/ContactAvatar";
import ContactApprovalModal from "../components/contactApprovalModal/ContactApprovalModal";
import FaqIcon from "../images/FaqIcon";


export default function LayoutNo({children}) {
    const {currentPage, finalSectionProgress} = useProgress();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navbarRef = useRef(null);
    const [navbarHeight, setNavbarHeight] = useState(0);
    const [viewResult, setViewResult] = useState(false);
    const {getUserCalculate} = useUserService();

    const {isAuthenticated, logout} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const {checkAppointment} = useUserService();


    const currentPath = location.pathname;
    const showProgressBarPaths = ['/public/test-orientation', '/public/test-orientation/', '/public/test-orientation/sujets', '/public/test-orientation/sujets/'];
    const showProgressBar = showProgressBarPaths.includes(currentPath);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isContactModalVisible, setIsContactModalVisible] = useState(false);
    // const [isHovered, setIsHovered] = useState(false);
    const [isModalApproved, setIsModalApproved] = useState(false);
    const [userId, setUserId] = useState(0);
    const asideRef = useRef(null);
    const userEmail = CryptoService.decryptData(localStorage.getItem('user_email'));
    const storedVisibility = localStorage.getItem('isContactButtonVisible');
    const [isContactButtonVisible, setIsContactButtonVisible] = useState(
        storedVisibility ? JSON.parse(storedVisibility) : false
    );
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const tooltipShown = localStorage.getItem("tooltipShown");
        if (isAuthenticated && !tooltipShown) {
            setShowTooltip(true);
            localStorage.setItem("tooltipShown", "true");
            const timer = setTimeout(() => setShowTooltip(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [isAuthenticated]);


    const onContactButtonHide = () => {
        setIsContactButtonVisible(false);
    };
    const handleOutsideClick = (event) => {
        if (asideRef.current && !asideRef.current.contains(event.target) && !event.target.closest('button')) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isSidebarOpen]);

    useEffect(() => {
        const user = localStorage.getItem('user_id');

        if (isAuthenticated && user) {
            const decryptedId = CryptoService.decryptData(user);
            setUserId(decryptedId);

            checkAppointment(decryptedId)
                .then(canMakeAppointment => {
                    setIsContactButtonVisible(canMakeAppointment);
                    localStorage.setItem('isContactButtonVisible', JSON.stringify(canMakeAppointment));
                })
                .catch(error => {
                    console.error("Error checking appointment:", error);
                    setIsContactButtonVisible(false);
                });
        } else {
            setIsContactButtonVisible(false);
            localStorage.removeItem('isContactButtonVisible');
        }
    }, [isAuthenticated, userEmail]);


    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);
    const showContactModal = () => setIsContactModalVisible(true);
    const hideContactModal = () => setIsContactModalVisible(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        const updateNavbarHeight = () => {
            if (navbarRef.current) {
                setNavbarHeight(navbarRef.current.offsetHeight);
            }
        };
        fetchData();
        updateNavbarHeight();
        window.addEventListener('resize', updateNavbarHeight);
        return () => {
            window.removeEventListener('resize', updateNavbarHeight);
        };
    }, []);


    const fetchData = async () => {
        if (isAuthenticated) {
            try {
                const userId = localStorage.getItem('user_id');
                const user = CryptoService.decryptData(userId);
                const response = await getUserCalculate(user);

                if (Array.isArray(response.data) && response.data.data.length === 0) {
                    setViewResult(false);
                } else {
                    setViewResult(true);
                }
            } catch (error) {
                console.error("Error fetching user calculate:", error);
                setViewResult(false);
            }
        }
    };

    const handleNavigation = () => {
        if (isAuthenticated) {
            const userId = localStorage.getItem('user_id');
            const encodedEncryptedUserId = encodeURIComponent(userId);
            navigate(`/enregistrements/${encodedEncryptedUserId}`);
        } else {
            showModal()
        }
    };

    const isSavedItemsPage = matchPath("/enregistrements/:userId", currentPath);

    const handleNavigationCheckTest = () => {
        if (viewResult) {
            navigate('/orientation');
        } else {
            navigate('/public/test-orientation');
        }
    };


    const hideAsideForPublicPaths = currentPath === '/public/test-orientationx' || currentPath === '/public/test-orientation/sujetsx';

    return (
        <>
            <div className="flex flex-col min-h-screen bg-white">
                {isAuthenticated  ? (
                    <Navbar setIsSidebarOpen={setIsSidebarOpen}/>
                ) : (
                    <LandingNav setIsSidebarOpen={setIsSidebarOpen}/>
                )}
                {!hideAsideForPublicPaths && (
                    <div
                        className={`lg:fixed top-[${navbarHeight}px] left-0 w-64 h-full bg-white border-r border-gray-300 z-20`}>
                        <aside
                            ref={asideRef}
                            className={`fixed inset-y-0 left-0 top-11 w-64 bg-white border-r border-gray-300 shadow-lg transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                            } lg:translate-x-0 lg:relative lg:w-64 lg:shadow-none lg:top-0 lg:z-0 z-30 flex flex-col`}
                        >
                            <nav className="py-5 px-4 w-full flex flex-col flex-1 lg:mt-5">
                                <ul className="flex-1">
                                    <li>
                                        <Link
                                            to="/chat"
                                            className={`block  text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/chat' ? ' bg-[#E8EEF2] ' : ''}`}
                                        >
                                            <button
                                                data-tracking={"Chat"}
                                                // className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg ${isSavedItemsPage ? 'bg-[#E8EEF2]' : ''} hover:bg-[#E8EEF2]`}
                                                className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg ${currentPath === '/chat' || currentPath === '/tout-savoir-sur-parcoursup' ? ' bg-[#E8EEF2] ' : ''}`}

                                            >
                                                <FaqIcon/>
                                                Chat
                                            </button>
                                        </Link>
                                    </li>
                                    <li>
                                        <button
                                            data-tracking={"Test d'orientation"}
                                            onClick={handleNavigationCheckTest}
                                            className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg 
                                            ${currentPath === '/public/test-orientation' || currentPath === '/public/test-orientation/sujets'  ? ' bg-[#E8EEF2] ' : ''}
                                            hover:bg-[#E8EEF2]`}
                                        >
                                            <LampIcon/>
                                            Test d'orientation
                                        </button>
                                    </li>

                                    <li className="flex flex-col">
                                        {/* Explorer Button with Dropdown Icon */}
                                        <button
                                            data-tracking={"Explorer"}
                                            onClick={toggleDropdown}
                                            type="button"
                                            className={`w-full py-3 px-2 flex items-center justify-between gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg hover:bg-[#E8EEF2] ${
                                                (isOpen && (currentPath === '/' || currentPath === '/metiers')) ||
                                                (!isOpen && (currentPath === '/' || currentPath === '/metiers')) ||
                                                (!isOpen && !(currentPath === '/ikigai' || currentPath.startsWith('/enregistrements') || currentPath === '/faq' || currentPath === '/apropos' || currentPath === '/orientation' || currentPath === '/tout-savoir-sur-parcoursup' || currentPath === '/apropos' || currentPath === '/public/test-orientation' || currentPath === '/public/test-orientation/sujets' || currentPath === '/chat'))
                                                    ? 'bg-[#E8EEF2] rounded-lg'
                                                    : ''
                                            }`}
                                        >
                                            <div className="flex items-center gap-x-1 ml-1">
                                                <Homeicon/>
                                                Explorer
                                            </div>
                                            {/* Dropdown Icon */}
                                            <svg
                                                className={`w-4 h-4  transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M19 9l-7 7-7-7"/>
                                            </svg>
                                        </button>

                                        {/* Dropdown Menu Items */}
                                        {isOpen && (
                                            <div className="pl-3 mt-2 flex flex-col space-y-1">
                                                <Link
                                                    to="/metiers"
                                                    className={`block px-2 py-3 text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/metiers' ? ' bg-[#E8EEF2] ' : ''}`}
                                                    onClick={() => setIsOpen(true)}
                                                    data-tracking={"Métiers"}

                                                >
                                                    Métiers
                                                </Link>
                                                <Link
                                                    to="/"
                                                    className={`block py-3 px-2 text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/' ? ' bg-[#E8EEF2] ' : ''}`}
                                                    onClick={() => setIsOpen(true)}
                                                    data-tracking={"Formations"}
                                                >
                                                    Formations
                                                </Link>
                                                <Link
                                                    to="/faq"
                                                    className={`block py-3 px-2 text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/faq' ? ' bg-[#E8EEF2] ' : ''}`}
                                                    onClick={() => setIsOpen(true)}
                                                    data-tracking={"FAQ"}
                                                >
                                                    FAQ
                                                </Link>
                                            </div>
                                        )}
                                    </li>
                                    <li>
                                        <button
                                            data-tracking={"Enregistrés"}
                                            onClick={handleNavigation}
                                            className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg ${isSavedItemsPage ? 'bg-[#E8EEF2]' : ''} hover:bg-[#E8EEF2]`}
                                        >
                                            <HeartIcon/>

                                            Enregistrés
                                        </button>
                                    </li>

                                    {isAuthenticated ? (
                                        <>
                                            <li className="flex lg:hidden absolute bottom-8">
                                                <Link to="/apropos" className="font-inter text-sm">
                                                    <button
                                                        className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg `}>
                                                        <QuestionCircleOutlined/>
                                                        À propos
                                                    </button>
                                                </Link>

                                            </li>
                                            <li className="flex lg:hidden absolute bottom-1">
                                                <button
                                                    onClick={logout}
                                                    className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg ${
                                                        isSavedItemsPage ? 'bg-[#E8EEF2]' : ''
                                                    } hover:bg-[#E8EEF2]`}
                                                >
                                                    <LogoutOutlined rotate={180}/>
                                                    Déconnexion
                                                </button>
                                            </li>
                                        </>
                                    ) : (
                                        <div className="flex-col lg:hidden absolute bottom-8">
                                            <li>
                                                <Link to="/apropos" className="font-inter text-sm">
                                                    <button
                                                        className="w-full ml-1 p-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg">
                                                        <span>À propos</span>
                                                    </button>
                                                </Link>

                                            </li>
                                            <li>
                                                <Link to="/public/inscription" className="font-inter text-sm">
                                                    <button
                                                        className="w-full ml-1 p-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg">
                                                        <span>S'inscrire</span>
                                                    </button>
                                                </Link>

                                            </li>
                                            <li>
                                                <button
                                                    className="w-full p-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg"
                                                    onClick={showModal}
                                                >
                                                    <img
                                                        src={UserIcon}
                                                        alt="Login Icon"
                                                        className=" h-5 w-5"
                                                    />
                                                    <span>S'identifier</span>

                                                </button>
                                            </li>
                                        </div>
                                    )}
                                    <div className=" lg:hidden absolute bottom-1 ml-2  ">
                                        {!isAuthenticated && (
                                            <div>
                                            <div
                                                className="md:text-sm text-xs justify-start text-gray-500  flex  gap-1">
                                                {/*<Link to="/mentions_legales" className="underline text-gray-500">*/}
                                                {/*    Mentions légales*/}
                                                {/*</Link>*/}
                                                <Link to="/conditions_generales" className="underline text-gray-500">
                                                    Conditions générales
                                                </Link>
                                            </div>
                                            <div
                                                className="md:text-sm text-xs justify-start text-gray-500  flex  gap-1">
                                                <Link to="/politique_de_confidentialite" className="underline text-gray-500">
                                                    Politique de confidentialité
                                                </Link>
                                            </div>
                                            </div>
                                        )}
                                    </div>
                                </ul>

                            </nav>
                        </aside>
                    </div>
                )}

                {showProgressBar && (
                    <div className={`fixed  ${isAuthenticated ? 'md:left-64 md:mt-14' : 'mt-12 '} w-full z-10`}>
                        <Progress isAuth={isAuthenticated} currentPart={currentPage} progress={finalSectionProgress}/>
                    </div>
                )}

                <div className={`flex flex-1 lg:ml-64 ${showProgressBar ? 'md:mt-16' : 'md:mt-12'}`}>
                    <main className="flex-1  bg-gray-50">
                        <div className="h-full w-full overflow-x-hidden overflow-y-hidden no-scrollbar">
                            {children}
                            <Outlet/>
                        </div>
                    </main>
                </div>
            </div>


            <Modal
                centered
                className="border-1 border-green-500 rounded-lg "
                open={isContactModalVisible}
                onCancel={hideContactModal}
                footer={null}
                closeIcon={null}
            >
                <div className="flex items-center justify-center  ">
                    <div className="w-full max-w-4xl  flex">
                        {/* Left Column */}
                        <div className="w-7/12 flex flex-col justify-center">
                            {/* Text */}
                            <p className="text-xl font-semibold text-gray-800">
                                Souhaitez-vous être contacté(e) par un conseiller ?
                            </p>
                            {/* Buttons */}
                            <div className="mt-1 flex space-x-4">
                                <button onClick={hideContactModal}
                                        className="md:px-6 px-3 md:py-2 py-1 border border-green-500 text-green-500 rounded-lg hover:bg-green-100">
                                    Non
                                </button>
                                <button
                                    // onClick={() => setIsModalApproved(true)}
                                    onClick={() => {
                                        if (isAuthenticated) {
                                            setIsModalApproved(true);
                                            hideContactModal();
                                        } else {
                                            showModal();
                                        }
                                    }}

                                    className="md:px-6 px-3 md:py-2 py-1 bg-green-500 text-white rounded-lg hover:bg-green-600">
                                    Oui
                                </button>
                            </div>
                        </div>

                        {/* Right Column */}
                        <div className="w-5/12 md:mr-0 mr-2 flex items-center justify-center">
                            <div className="  flex items-center justify-center">
                                <ContactAvatar/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ContactApprovalModal
                userId={userId}
                userEmail={userEmail}
                isVisible={isModalApproved}
                onClose={() => setIsModalApproved(false)}
                onContactButtonHide={onContactButtonHide}
            />
            <LoginModal visible={isModalVisible} onHide={hideModal}/>
        </>

    );
}


