import React, { useState } from 'react';
import { Modal, Input, Button, Typography, Alert } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useAuthService } from '../../../services/auth.service';
import { SuccessResetModal } from './SuccessResetModal';

const { Title, Text } = Typography;

export function RenewPasswordModal({ email, visible, onHide }) {
    const [resetVisible, setResetVisible] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { resetPassword } = useAuthService();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordsMatch(e.target.value === confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordsMatch(password === e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (passwordsMatch) {
            try {
                const response = await resetPassword(email, password);
                if (response.data.status === 'success') {
                    setResetVisible(true);
                    handleClose();
                } else {
                    // Handle error
                }
            } catch (error) {
                // Handle error
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setPassword('');
        setConfirmPassword('');
        setPasswordsMatch(true);
        setShowPassword(false);
        onHide();
    };

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onHide}
                width={400}
                footer={null}
                centered
            >
                <Title level={4} style={{ textAlign: 'center' }}>
                    Crée un nouveau mot de passe
                </Title>
                <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: '1rem' }}>
                    Vous êtes en train de changer le mot de passe pour l'adresse e-mail : <strong>{email}</strong>
                </Text>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Input
                            style={{ fontSize: '16px' }}
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Nouveau mot de passe"
                            suffix={
                                showPassword ? (
                                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                                ) : (
                                    <EyeOutlined onClick={togglePasswordVisibility} />
                                )
                            }
                            className={`w-full ${!passwordsMatch ? 'border-red-500' : ''}`}
                        />
                    </div>
                    <div>
                        <Input
                            style={{ fontSize: '16px' }}
                            type={showPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            placeholder="Confirmer le mot de passe"
                            suffix={
                                showPassword ? (
                                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                                ) : (
                                    <EyeOutlined onClick={togglePasswordVisibility} />
                                )
                            }
                            className={`w-full ${!passwordsMatch ? 'border-red-500' : ''}`}
                        />
                    </div>
                    {!passwordsMatch && (
                        <Alert
                            message="Les mots de passe ne correspondent pas."
                            type="error"
                            showIcon
                            style={{ marginBottom: '1rem' }}
                        />
                    )}
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        className="w-full"
                    >
                        Continuer
                    </Button>
                </form>
            </Modal>
            <SuccessResetModal visible={resetVisible} onHideSuccessReset={() => setResetVisible(false)} />
        </>
    );
}
