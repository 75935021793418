import React from 'react';
import UserModal from "./inscriptionProcess/inscriptionSteps";
import logo from "../images/logo.svg";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Registration() {
    return (
        <>
            <Helmet>
                <title>Inscription - Rejoignez Linkyways</title>
                <meta name="description" content="Créez votre compte et commencez votre parcours d'orientation avec Linkyways." />
                <link rel="canonical" href="https://linkyways.com/public/inscription" />
            </Helmet>
            <div
                className="bg-white"
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <UserModal/>
            </div>
        </>
    );
}
